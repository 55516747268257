import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalTheme } from '../../theme';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { alpha } from '@mui/system';

export default function MantleDisplay() {
    const { banner_for, refer_other_id } = useParams();
    const navigate = useNavigate();
    const [mantleData, setMantleData] = useState([]);
    const [value, setValue] = useState(null);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    // Fetch Mantle Data and Keywords
    useEffect(() => {
        const fetchMantleData = async () => {
            try {
                const response = await fetch('https://aaryanholidays.in/frontend/homeBanner');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setMantleData(data);
            } catch (error) {
                console.error('Error fetching mantle data: ', error);
            }
        };

        const fetchKeywords = async () => {
            try {
                const response = await fetch('https://aaryanholidays.in/frontend/keywords');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                if (data.keywords) {
                    // Remove duplicate keywords using Set
                    const uniqueKeywords = Array.from(new Set(data.keywords.split(',').map(keyword => keyword.trim())));
                    setOptions(uniqueKeywords);
                } else {
                    console.error('No keywords found in the response.');
                }
            } catch (error) {
                console.error('Error fetching keywords: ', error);
            }
        };

        fetchMantleData();
        fetchKeywords();
    }, [banner_for, refer_other_id]);

    // Handles keyword input and selection
    const handleKeywordChange = (event, newValue) => {
        setValue(newValue);
        setInputValue(typeof newValue === 'string' ? newValue : newValue?.inputValue || '');
    };

    // Custom filtering function to only display options that match the start of the input
    const filterOptions = (options, { inputValue }) => {
        // Don't show any options if there's no input value
        if (!inputValue.trim()) {
            return [];
        }
        return options.filter(option => option.toLowerCase().startsWith(inputValue.toLowerCase()));
    };

    // Handle Search Button Click or Enter Press
    const handleSearchClick = () => {
        if (inputValue.trim()) {
            const query = new URLSearchParams({
                keywords: inputValue.trim(),
            }).toString();
            navigate(`/searchresultpage?${query}`);
        } else {
            alert('Please enter a keyword to search');
        }
    };

    // Trigger Search on Enter Key
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                {mantleData.map((item, index) => (
                    <Card component='section' id="headerContainer" key={index} elevation={0} sx={{
                        width: '100%', position: 'relative', zIndex: 1,
                        height: {
                            xs: 400,
                            lg: 833
                        },
                        borderRadius: 0,
                    }}>
                        <Container fixed sx={{
                            position: {
                                xs: 'relative',
                                md: 'absolute'
                            },
                            height: '100%',
                            width: '100%',
                            left: 0, right: 0, bottom: 0, top: 0,
                            zIndex: 2
                        }}>
                            <Grid key={index}>
                                <CardContent id={`mantleText${index}`}
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        margin: '0 auto',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        width: {
                                            md: '70%'
                                        },
                                        flexDirection: 'column',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                    }}>
                                    <Typography id={`mantleHeading${index}`} gutterBottom variant='h1' color='white'>{item.heading}</Typography>
                                    <Typography id={`mantleSubHeading${index}`} component='p' variant="subtitle1" color='white'
                                        sx={{
                                            mb: 3,
                                            px: '5%'
                                        }}
                                    >
                                        {item.sub_heading}
                                    </Typography>

                                    <Autocomplete
                                        disablePortal
                                        value={value}
                                        inputValue={inputValue}
                                        onChange={handleKeywordChange}
                                        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                                        filterOptions={filterOptions} // Custom filter function for exact match search
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="searchDestinations"
                                        options={options}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <>
                                                <TextField
                                                    {...params}
                                                    placeholder='Search keywords'
                                                    sx={{
                                                        width: '99%',
                                                        pl: 5,
                                                        "& ::placeholder": {
                                                            opacity: 1
                                                        }
                                                    }}
                                                    onKeyDown={handleKeyDown} // Search when Enter is pressed
                                                />
                                                <Box display='flex' sx={{
                                                    position: 'absolute', alignItems: 'center',
                                                    left: 5, top: 0, height: '100%'
                                                }}>
                                                    <Box>
                                                        <IconButton
                                                            onClick={handleSearchClick} // Search when search button is clicked
                                                            aria-label='search'
                                                        >
                                                            <SearchOutlinedIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </>
                                        )}
                                        sx={{
                                            position: 'relative',
                                            backgroundColor: alpha(GlobalTheme.palette.common.white, .8),
                                            borderRadius: 12.5,
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderRadius: 0,
                                                border: 'none'
                                            },
                                            "&:hover": {
                                                boxShadow: GlobalTheme.shadows[6],
                                                borderColor: 'transparent',
                                                transition: 'box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out'
                                            },
                                        }}
                                    />
                                </CardContent>
                            </Grid>
                        </Container>
                        <Grid
                            id="mask"
                            position='absolute'
                            sx={{
                                background: 'linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.3) 100%)',
                                zIndex: 1,
                            }}
                            width='100%'
                            height='100%'
                        />
                        {item.type === 'video' ? (
                            <CardMedia
                                id={`mantle${index}`}
                                key={index}
                                component="video"
                                autoPlay
                                loop
                                muted
                                playsInline
                                sx={{
                                    position: 'absolute',
                                    transform: 'translateX(-50%) translateY(-50%)',
                                    minWidth: '100%',
                                    minHeight: '100%',
                                    height: 'auto',
                                    objectFit: 'cover',
                                    top: {
                                        xs: '50%',
                                    },
                                    left: {
                                        xs: '50%',
                                    },
                                }}>
                                <source src={`https://aaryanholidays.in/express/uploads/${item.src}`} type="video/mp4" />
                                <Typography variant='subtitle2' component='p' color='white'>
                                    {item.sub_heading}
                                </Typography>
                            </CardMedia>
                        ) : (
                            <CardMedia
                                id={`mantle${index}`}
                                key={index}
                                component="img"
                                sx={{
                                    position: 'absolute',
                                    transform: 'translateX(-50%) translateY(-50%)',
                                    minWidth: '100%',
                                    minHeight: '100%',
                                    height: 'auto',
                                    objectFit: 'cover',
                                    top: {
                                        xs: '50%',
                                    },
                                    left: {
                                        xs: '50%',
                                    },
                                }}
                                src={`https://aaryanholidays.in/express/uploads/${item.src}`}
                                alt={item.heading}
                            />
                        )}
                    </Card>
                ))}
            </ThemeProvider>
        </React.Fragment>
    );
}
