import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Virtual, Navigation, Pagination, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { GlobalTheme } from '../../theme';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Stack from '@mui/material/Stack';
import HotelStyle from './assets/scss/hotels.module.scss';


const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};

export default function HotelsHome() {
    const [open, setOpen] = React.useState(false);
    const { refer_other_id } = useParams();
    const [hotelsData, setHotelsData] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [swiperRef, setSwiperRef] = useState(null);

    const handleCardClick = (id) => {
        navigate(`/hotels/${id}`);
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {

        const fetchHotelsData = async () => {
            try {
                const response = await fetch(`https://aaryanholidays.in/frontend/special-feature-home-page`);
                const data = await response.json();

                if (Array.isArray(data)) {
                    // Filter only "Hotel" type entries
                    const hotelData = data.filter(item => item.type === 'Hotel').map(item => ({
                        ...item,
                        image: `https://aaryanholidays.in/express/uploads/${item.thumbnail}`
                    }));

                    setHotelsData(hotelData);
                } else {
                    console.error('Unexpected data format:', data);
                }
            } catch (error) {
                console.error('Error fetching hotels data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchHotelsData();
    }, []);

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    if (!Array.isArray(hotelsData) || hotelsData.length === 0) {
        return <Typography>No hotels data available.</Typography>;
    }

    return (
        <ThemeProvider theme={GlobalTheme}>
            <CssBaseline />

            <Swiper
                className={HotelStyle.hotels}
                modules={[Virtual, Navigation, Pagination, Keyboard]}
                onSwiper={setSwiperRef}
                breakpoints={{
                    0: { slidesPerView: 1 },
                    600: { slidesPerView: 2 },
                    900: { slidesPerView: 3 },
                    1200: { slidesPerView: 4 }
                }}
                spaceBetween={30}
                navigation={true}
                virtual
                freeMode={true}
                grabCursor={true}
                keyboard={{ enabled: true }}
            >
                {hotelsData.map((hotel) => (
                    <SwiperSlide key={hotel.id}>
                        <Card
                            id={`hotelsCard-${hotel.id}`}
                            sx={{
                                boxShadow: '0 0 1px 1px rgba(0,0,0,0.1)',
                                p: 0,
                                mb: 3,
                                "&:hover": {
                                    boxShadow: '0 0 30px 5px rgba(0,0,0,0.2)',
                                }
                            }}
                        >
                            <CardContent
                                id={`hotelsBtn-${hotel.id}`}
                                component='figure'
                                sx={{
                                    p: 0,
                                    m: 0,
                                    borderRadius: 2,
                                    "&:hover": {
                                        backgroundColor: 'transparent',
                                        boxShadow: 'none'
                                    }
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="153"
                                    width="220"
                                    image={hotel.image}
                                    alt={hotel.title}
                                    sx={{
                                        borderTopLeftRadius: 1,
                                        borderTopRightRadius: 1,
                                        borderBottomRightRadius: 0,
                                        borderBottomLeftRadius: 0,
                                    }}
                                />
                                <CardContent component='figcaption' sx={{ textAlign: 'left', px: 2 }}>
                                    <Typography gutterBottom variant="h4" component="h3" sx={{ textTransform: 'uppercase', mb: 2 }}>
                                        {hotel.title}
                                    </Typography>
                                    <Stack component='address' spacing={1} direction='row' sx={{ mb: 2, fontStyle: 'normal' }}>
                                        <LocationOnOutlinedIcon />
                                        <Typography variant='subtitle2' component='p'>
                                            {truncateText(hotel.description, 50)}
                                        </Typography>
                                    </Stack>
                                    <Button variant='outlined' sx={{ borderRadius: 5 }} onClick={handleClickOpen}>
                                        Enquiry
                                    </Button>
                                </CardContent>
                            </CardContent>
                        </Card>
                    </SwiperSlide>
                ))}
            </Swiper>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        handleClose();
                    },
                }}
            >
                <DialogTitle>Hotel Name</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tell us what you want to know about the hotel and we are glad to help you.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="normal"
                        id="name"
                        name="name"
                        label="Your name"
                        type="text"
                        fullWidth
                        variant="standard"
                        placeholder='e.g: John Smith'
                    />
                    <TextField
                        autoFocus
                        required
                        margin="normal"
                        id="name"
                        name="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        placeholder='e.g: john.smith@domain.com'
                    />
                    <TextField
                        margin="normal"
                        id="phone"
                        name="phone"
                        label="Phone"
                        type="tel"
                        fullWidth
                        variant="standard"
                        placeholder='e.g: +91 00000 00000'
                    />
                    <TextField
                        id="msg"
                        margin="normal"
                        required
                        multiline
                        minRows={3}
                        maxRows={6}
                        label="Enquiry"
                        fullWidth
                        placeholder='What you would like to enquire about'
                        variant="standard"
                        value=''
                    />

                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={handleClose} sx={{ textTransform: 'capitalize', color: GlobalTheme.palette.common.bodyTxt }}>Cancel</Button>
                    <Button type="submit" variant='contained' sx={{ textTransform: 'capitalize', borderRadius: 5, color: GlobalTheme.palette.common.white, px: 3 }}>Enquire</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>


    );
}
