import React, { useState, useEffect } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, alpha } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import { Stepper, Step, StepLabel, InputLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { GlobalTheme } from './../../theme';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';

import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import Stack from '@mui/material/Stack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { ReactComponent as Logo } from './assets/images/logo_full.svg';
import axios from 'axios';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, onClose }) {
    const [contentHeight, setContentHeight] = useState(0);
    const [adultCount, setAdultCount] = useState(0);
    const [childCount, setChildCount] = useState(0);
    const [childAges, setChildAges] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [travelDate, setTravelDate] = useState(null);
    const [duration, setDuration] = useState('');
    const [formData, setFormData] = useState({
        destination: '',
        fullName: '',
        phone: '',
        email: '',
        preferences: ''
    });
    // Validation states
    const [errors, setErrors] = useState({
        fullName: '',
        email: '',
        phone: ''
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const steps = [
        'Step 1',
        'Step 2',
        'Step 3',
        'Step 4',
        'Step 5'
    ];
    const validateStep = (step) => {
        let isValid = true;
        const newErrors = { ...errors };

        switch (step) {
            case 0:
                if (!formData.destination.trim()) {
                    newErrors.destination = 'Destination is required';
                    isValid = false;
                }
                else{
                    newErrors.destination = '';
                  
                }
                break;
            case 1:
                if (!travelDate) {
                    newErrors.travelDate = 'Travel date is required';
                    isValid = false;
                }
                else{
                    newErrors.travelDate = '';
                    
                }
                if (!duration.trim()) {
                    newErrors.duration = 'Duration is required';
                   
                }
                else{
                    newErrors.duration = '';
                    
                }
                break;
            case 2:
                if (!formData.fullName.trim()) {
                    newErrors.fullName = 'Full name is required';
                    isValid = false;
                }
                else{
                    newErrors.fullName = '';
                    
                }
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!formData.email.trim()) {
                    newErrors.email = 'Email is required';
                    isValid = false;
                } else if (!emailRegex.test(formData.email)) {
                    newErrors.email = 'Invalid email format';
                    isValid = false;
                }
                else{
                    newErrors.email = '';
                
                }
                const phoneRegex = /^[0-9]{10}$/;
                if (!formData.phone.trim()) {
                    newErrors.phone = 'Phone number is required';
                    isValid = false;
                } else if (!phoneRegex.test(formData.phone)) {
                    newErrors.phone = 'Invalid phone number';
                    isValid = false;
                }
                else{
                    newErrors.phone = '';
                   
                }
                break;
            default:
                break;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleNext = () => {
        if (validateStep(activeStep)) {
            setActiveStep((prevStep) => prevStep + 1);
        }
    };
    

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleAdultChange = (type) => {
        setAdultCount((prevCount) => (type === 'add' ? prevCount + 1 : prevCount > 1 ? prevCount - 1 : 1)); 
    };

    const handleChildChange = (type) => {
        if (type === 'add') {
            setChildCount((prevCount) => prevCount + 1);
            setChildAges([...childAges, '']);
        } else if (type === 'remove' && childCount > 0) {
            setChildCount((prevCount) => prevCount - 1);
            setChildAges((prevAges) => prevAges.slice(0, -1)); // Remove last age input
        }
    };

    const handleAgeChange = (index, age) => {
        const updatedAges = [...childAges];
        updatedAges[index] = age;
        setChildAges(updatedAges);
    };

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const listData = [
        { label: 'Travellers:', value: `Adults: ${adultCount}, Children: ${childCount}, Ages: ${childAges.join(', ')}` },
        { label: 'Destination:', value: formData.destination },
        { label: 'Travel Date:', value: travelDate?.format('YYYY-MM-DD') },
        { label: 'Trip Duration:', value: `${duration} days` },
        { label: 'Full Name:', value: formData.fullName },
        { label: 'Phone:', value: formData.phone },
        { label: 'Email:', value: formData.email },
        { label: 'Preferences:', value: formData.preferences }
    ];

    // const handleSubmit = () => {
    //     // Submit logic here
    //     console.log('Form submitted:', { formData, adultCount, childCount, childAges, travelDate, duration });
    // };

    const closeSubmission = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    };

    const handleSubmit = async () => {
        if (!validateStep(4)) return;
        try {
            const response = await axios.post('https://aaryanholidays.in/backend/submit-travel-form', {
                destination: formData.destination,
                travellers: `Adults: ${adultCount}, Children: ${childCount}, Ages: ${childAges.join(', ')}`,
                travel_date: travelDate?.format('YYYY-MM-DD'),
                trip_duration: duration,
                full_name: formData.fullName,
                phone: formData.phone,
                email: formData.email,
                preferences: formData.preferences
            });
             // Display response message in the snackbar
             setSnackbarMessage(response.data.message);
             setSnackbarOpen(true);
 
             // Close the dialog after 10 seconds
             setTimeout(() => {
                 handleCloseForm();
             }, 100);
        } catch (error) {
            console.error('Error submitting form:', error);
            setSnackbarMessage('Error submitting form:');
             setSnackbarOpen(true);
            alert('Error submitting form. Please try again.');
        }
    };
    const stepImages = [
        {
            punchLine: 'Ready to discover new horizons?',
            subline: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            src: 'https://as2.ftcdn.net/v2/jpg/05/73/29/09/1000_F_573290990_xwAe8yTs5ZFeQtgLfkwly9j78ZM3qZs8.jpg'
        },
        {
            punchLine: 'Ready to discover new horizons?',
            subline: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            src: 'https://as1.ftcdn.net/v2/jpg/05/65/53/38/1000_F_565533854_9kUMcL5Au8dNHIzXP0lLmUBhyL6X3IjQ.jpg'
        },
        {
            punchLine: 'Ready to discover new horizons?',
            subline: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            src: 'https://as2.ftcdn.net/v2/jpg/05/73/29/09/1000_F_573290990_xwAe8yTs5ZFeQtgLfkwly9j78ZM3qZs8.jpg'
        },
        {
            punchLine: 'Ready to discover new horizons?',
            subline: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            src: 'https://as1.ftcdn.net/v2/jpg/05/65/53/38/1000_F_565533854_9kUMcL5Au8dNHIzXP0lLmUBhyL6X3IjQ.jpg'
        },
        {
            punchLine: 'Ready to discover new horizons?',
            subline: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            src: 'https://as2.ftcdn.net/v2/jpg/05/73/29/09/1000_F_573290990_xwAe8yTs5ZFeQtgLfkwly9j78ZM3qZs8.jpg'
        },
    ];
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    const handleCloseForm = () => {
        // Reset form data to default values
        setAdultCount(0);
        setChildCount(0);
        setChildAges([]);
        setActiveStep(0);
        setTravelDate(null);
        setDuration('');
        setFormData({
            destination: '',
            fullName: '',
            phone: '',
            email: '',
            preferences: ''
        });
        renderStepContentnew(0);
        onClose();
    };

   
    const renderStepContentnew = (step) => {
        
        switch (step) {
            case 0:
                return (
                    <Box>
                        <Typography variant="h6" component='p' >Where would you like to go?</Typography>
                        <TextField
                            variant='standard'
                            label="Destination"
                            name="destination"
                            value={formData.destination}
                            onChange={handleChange}
                            error={!!errors.destination}
                            helperText={errors.destination}
                            placeholder='Enter place name (Thailand, Dubai, Kerala)'
                            fullWidth
                            margin="normal"
                        />
                        <Typography variant="h6" sx={{ mt: 3 }}>I am traveling with:</Typography>
                        <Stack direction='column'>
                            {/* Adult Counter */}
                            <Box display="flex" alignItems="center" my={2}>
                                <Stack direction='row' spacing={6}>
                                    <Typography>Adults
                                        <Typography component='span' display='block' color={GlobalTheme.palette.common.bodyTxt}>Ages 18 or above</Typography>
                                    </Typography>

                                    <Box display='flex' alignItems='center'>
                                        <Stack direction='row' alignItems='center'>
                                            <IconButton onClick={() => handleAdultChange('remove')} disabled={adultCount === 0}>
                                                <RemoveIcon />
                                            </IconButton>
                                            <TextField value={adultCount} inputProps={{ readOnly: true }} sx={{
                                                width: 50,
                                                "& .MuiOutlinedInput-root": {
                                                    "& .MuiInputBase-input": {
                                                        textAlign: 'center'
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: 'transparent',
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: 'transparent',
                                                    },
                                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: 'transparent',
                                                    },
                                                }
                                            }} />
                                            <IconButton onClick={() => handleAdultChange('add')}>
                                                <AddIcon />
                                            </IconButton>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Box>

                            {/* Child Counter */}
                            <Box display="flex" alignItems="center" my={2}>
                                <Stack direction='row' spacing={13}>
                                    <Typography>Children
                                        <Typography component='span' display='block' color={GlobalTheme.palette.common.bodyTxt}>Ages 0-17</Typography>
                                    </Typography>

                                    <Box display='flex' alignItems='center'>
                                        <Stack direction='row' alignItems='center'>
                                            <IconButton onClick={() => handleChildChange('remove')} disabled={childCount === 0}>
                                                <RemoveIcon />
                                            </IconButton>
                                            <TextField value={childCount} inputProps={{ readOnly: true }}
                                                sx={{
                                                    width: 50,
                                                    borderColor: 'transparent',
                                                    "& .MuiOutlinedInput-root": {
                                                        "& .MuiInputBase-input": {
                                                            textAlign: 'center'
                                                        },
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: 'transparent',
                                                        },
                                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: 'transparent',
                                                        },
                                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: 'transparent',
                                                        },
                                                    }
                                                }} />
                                            <IconButton onClick={() => handleChildChange('add')} disabled={childCount === 9}>
                                                <AddIcon />
                                            </IconButton>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Box>
                        </Stack>

                        {/* Child Age Selectors */}
                        {childCount > 0 && (
                            <Stack mb={2} direction='column'>
                                {childAges.map((age, index) => (
                                    <Stack direction='row' spacing={6} key={index} mb={3}>
                                        <InputLabel sx={{ width: 130 }}>Age of child {index + 1}</InputLabel>
                                        <Select
                                            variant="standard"
                                            fullWidth
                                            sx={{ maxWidth: 150 }}
                                            value={age}
                                            onChange={(event) => handleAgeChange(index, event.target.value)}
                                            displayEmpty
                                        >
                                            {[...Array(17)].map((_, i) => (
                                                <MenuItem key={i} value={i + 1}>
                                                    {i + 1} years
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Stack>
                                ))}
                            </Stack>
                        )}
                    </Box>
                );
            case 1:
                return (
                    <Box>
                        <Typography variant="h6" component='p'>When do you want to go?</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
        label="Travel Date"
        value={travelDate}
        onChange={(newDate) => setTravelDate(newDate)}
        minDate={dayjs().add(1, 'day')}  // Disable all dates before tomorrow
        renderInput={(params) => (
            <TextField
                {...params}
                fullWidth
                error={!!errors.travelDate}  // Pass the error prop to TextField
                helperText={errors.travelDate}  // Pass the helperText prop to TextField
            />
        )}
        sx={{ my: 2 }}
    />
                        </LocalizationProvider>
                        <TextField
                            label="Trip Duration (in days)"
                            name="duration"
                            variant='standard'
                            placeholder='5 days'
                            value={duration}
                            error={!!errors.duration}
                            helperText={errors.duration}
                            onChange={(e) => setDuration(e.target.value)}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                    </Box>
                );
            case 2:
                return (
                    <Box>
                        <Typography variant="h6" component='p'>Your details</Typography>
                        <TextField
                            label="Full Name"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            fullWidth
                            required
                            placeholder='e.g: John Smith'
                            variant='standard'
                            error={!!errors.fullName}
                            helperText={errors.fullName}
                            sx={{ my: 1 }}
                        />
                        <TextField
                            label="Phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            fullWidth
                            required
                            placeholder='e.g: +91 xxxx xxx xxx'
                            variant='standard'
                            error={!!errors.phone}
                            helperText={errors.phone}
                            sx={{ my: 1 }}
                        />
                        <TextField
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email}
                            fullWidth
                            required
                            placeholder='e.g: yourname@example.com'
                            variant='standard'
                            sx={{ my: 1 }}
                        />
                    </Box>
                );
            case 3:
                return (
                    <Box>
                        <Typography variant="h6" component='p'>Tell us your choice & preference</Typography>
                        <TextField
                            label="Details"
                            name="preferences"
                            variant='standard'
                            placeholder='e.g: I want to have a sea facing hotel with bar facility.'
                            value={formData.preferences}
                            onChange={handleChange}
                            multiline
                            minRows={2}
                            maxRows={4}
                            fullWidth
                        />
                    </Box>
                );
            case 4:
                return (
                    <Box>
                        <Typography variant="h5" component='h2' gutterBottom>All set and done</Typography>

                        <Box container sx={{ mt: 4 }}>
                            {listData.map((item, index) => (
                                <Box sx={{ display: 'flex' }} key={index}>
                                    <Grid item xs={12} md={3} component="span" mb={2} sx={{ fontWeight: 500 }}>{item.label}</Grid>
                                    <Grid item xs={12} md={9} component="span" mb={2} sx={{ color: GlobalTheme.palette.common.bodyTxt }}>{item.value}</Grid>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                );
            default:
                return 'Unknown step';
        }
    };

    const calculateContentHeight = () => {
        const logo = document.getElementById('logo');
        const travelNav = document.getElementById('travelRequestNav');

        if (logo && travelNav) {
            const windowHeight = window.innerHeight;
            const logoHeight = logo.offsetHeight;
            const travelNavHeight = travelNav.offsetHeight;

            const newContentHeight = windowHeight - logoHeight - travelNavHeight;
            setContentHeight(newContentHeight);
        }
    };

    useEffect(() => {
        // Calculate height on initial render
        calculateContentHeight();

        // Recalculate height on window resize
        window.addEventListener('resize', calculateContentHeight);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', calculateContentHeight);
        };
    }, []);

    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />

                <Dialog
                    fullScreen
                    open={open}
                    onClose={onClose}
                    TransitionComponent={Transition}
                >
                    <Grid container sx={{ width: '100%', height: '100%' }}>
                        <Grid id='panelLeft' item xs={12} sm={7} display='flex' alignItems='center' sx={{ height: '100%', position: 'relative' }}>
                            <CardContent sx={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: alpha(GlobalTheme.palette.common.black, .2)
                            }}>
                                <Grid item xs={9} sx={{ pl: 5 }}>
                                    <Typography component='h2' variant='h2' sx={{ color: GlobalTheme.palette.common.white }}>{stepImages[activeStep].punchLine}</Typography>
                                    <Typography component='p' variant='subtitle1' sx={{ color: GlobalTheme.palette.common.white }}>{stepImages[activeStep].subline}</Typography>
                                </Grid>
                            </CardContent>

                            <CardMedia
                                component="img"
                                alt=""
                                image={stepImages[activeStep].src}
                                sx={{ height: '100%', width: '100%', objectFit: 'cover' }}
                            />
                        </Grid>
                        <Grid id='panelRight' item xs={12} sm={5} display='flex' alignItems='center' sx={{ position: 'relative', backgroundColor: GlobalTheme.palette.common.grayLight, px: 10 }}>
                            <Tooltip title="Close">
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={onClose}
                                    aria-label="close"
                                    sx={{
                                        position: 'absolute',
                                        top: 20,
                                        right: 20
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <CardContent sx={{ width: '100%' }} id='container'>
                                    <Grid id='logo' item xs sx={{ textAlign: 'center' }}>
                                        <Logo />
                                    </Grid>
                                    <Box id='travelContent' sx={{ width: '100%', maxWidth: 600, margin: '0 auto', padding: 3, overflowY: 'auto' }} style={{ height: contentHeight - 40 }}>
                                        <Stepper activeStep={activeStep} component='h2'>
                                            <Step key={activeStep} component='span'>
                                                <StepLabel component='span' sx={{ fontSize: GlobalTheme.typography.h1.fontSize }}>{steps[activeStep]}</StepLabel>
                                            </Step>
                                        </Stepper>

                                        <Box sx={{ mt: 3 }}>
                                            {renderStepContentnew(activeStep)}
                                        </Box>
                                    </Box>
                                </CardContent>

                                <Box id='travelRequestNav' component='nav' sx={{
                                    display: 'flex',
                                    py: 4,
                                    mt: 3,
                                    textAlign: 'right',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    position: 'absolute', left: 0, bottom: 0, right: 0, pr: 10
                                }} aria-label='travel request nav panel'>
                                    {activeStep !== 0 && (
                                        <Tooltip title='Previous'>
                                            <IconButton variant='contained' color='primary' onClick={handleBack} sx={{ mr: 1 }}>
                                                <ArrowBackIosNewIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {activeStep === 4 && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSubmit}
                                            sx={{ color: GlobalTheme.palette.common.white, borderRadius: 40, boxShadow: 'none', px: 4, py: 1 }}
                                        >
                                            Lets go
                                        </Button>
                                    )}
                                    {activeStep < steps.length - 1 && (
                                        <Tooltip title='Next'>
                                            <IconButton onClick={handleNext} variant='contained' color='primary'>
                                                <ArrowForwardIosIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                </Dialog>
                {/* Snackbar for displaying response message */}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={snackbarMessage}
                />
            </ThemeProvider>
        </React.Fragment>
    );
}
