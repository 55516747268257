import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';

import { GlobalTheme } from '../../theme';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { alpha } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Logo from '../logo/logo';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import NavbarStyle from './assets/scss/navbar.module.scss';
import './assets/scss/menu.css';
import './assets/scss/menu-transitions.css';
import './assets/scss/megamenu.scss';
import TravelRequest from './travelRequest';

const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};

const MenuComponent = (props) => {
    const isMobile = useMediaQuery(GlobalTheme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('md'));
    const { window } = props;
    const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';
    const [destinationData, setDestinationData] = useState([]);
    const [splDestinationData, setsplDestinationData] = useState([]);
    const [continentsData, setcontinentsData] = useState([]);
    const [tourTypeData, setallTourTypeData] = useState([]);
    const [menuData, setMenuData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenuId, setOpenMenuId] = useState(null);
    const [expanded, setExpanded] = React.useState('panel1');
    const [expandedParent, setExpandedParent] = useState(false);
    const [expandedChild, setExpandedChild] = useState(false);

    // const for style object
    const accordionStyles = {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderBottom: `1px solid ${GlobalTheme.palette.common.grayDark}`,
        mb: 0,
        borderRadius: 0,
        "& .MuiPaper-root-MuiAccordion-root::before": {
            display: 'none'
        },
        "&.Mui-expanded": {
            my: 0
        }
    };

    const accordionDetailsStyles = {
        p: 0
    };

    const gridStyles = {
        pl: 2,
        mb: 2.62
    };

    // Define a constant for the button styles
    const buttonStyles = {
        variant: 'outlined',
        size: 'small'
    };

    const handleParentChange = (panel) => (event, isExpanded) => {
        setExpandedParent(isExpanded ? panel : false);
    };

    const handleChildChange = (panel) => (event, isExpanded) => {
        setExpandedChild(isExpanded ? panel : false);
    };

    // const container = window !== undefined ? () => window().document.body : undefined;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
        target: window ? window() : undefined,
    });

    // mobile static links
    const linkItems = [
        { href: "/about", label: "About us" },
        { href: "https://aaryanholidays.in/blog", label: "Blog" },
        { href: "/contact", label: "Contact us" },
    ];


    useEffect(() => {
        const fetchData = async () => {
            try {
                const destinationsResponse = await fetch('https://aaryanholidays.in/frontend/alldestinations');
                const destinationsData = await destinationsResponse.json();
                const destinationsDataspl = destinationsData;
                const promotedData = destinationsData.filter(destination => destination.promote === 1);
                const splData = destinationsDataspl.filter(destination => destination.is_specialized === 1);
                console.log(splData);


                setDestinationData(promotedData);
                setsplDestinationData(splData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const continentsResponse = await fetch('https://aaryanholidays.in/frontend/continents');
                const continentsData = await continentsResponse.json();

                setcontinentsData(continentsData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const allTourTypeResponse = await fetch('https://aaryanholidays.in/frontend/all-tour-types');
                const allTourTypeData = await allTourTypeResponse.json();

                setallTourTypeData(allTourTypeData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {isDesktop && (
                <Grid className="aaryan-wrapper" display='flex' justifyContent='space-between' alignItems='center'
                    sx={{
                        boxShadow: `0 0 0 1px ${alpha(GlobalTheme.palette.common.white, 0.2)}`

                    }}>
                    <List className="aaryan-menu" id='secA'>
                        <li className="aaryan-menu-mega-blog aaryan-menu-mega-blog-parent">
                            <Link href="/"
                                className={trigger ? 'scrolled' : ''}
                            > Home
                            </Link>
                        </li>

                        <li className="aaryan-menu-mega-blog aaryan-menu-mega-blog-parent">
                            <Link href="#"
                                className={trigger ? 'scrolled' : ''}
                            >Tours</Link>
                            <div className="minHeight">
                                <List className="aaryan-menu-mega-blog-nav">
                                    <li className="aaryan-active-menu-item">
                                        <Link href="#">Popular Tours</Link>

                                        <div className="aaryan-grid aaryan-grid-lined bgGray minHeight">
                                            <div className="aaryan-row">
                                                <Typography variant='h3' component='h2' sx={{ ml: 2, mt: 2 }} gutterBottom>What's Hot</Typography>
                                                {destinationData.map((tour, index) => (
                                                    <div className="aaryan-col-3 aaryan-col-3__no-border">
                                                        <Link href={`/tour-details/4/${tour.id}`} className='position-relative'>
                                                            {tour.type === 'video' ? (
                                                                <video
                                                                    src={`${BASE_MEDIA_URL}${tour.src}`}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={`${BASE_MEDIA_URL}${tour.src}`}
                                                                    alt={tour.alt || 'Tour Image'}
                                                                />
                                                            )}

                                                            <span className="aaryan-c-title borderRadius" dangerouslySetInnerHTML={{ __html: tour.thumbnail_heading }} />
                                                        </Link>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>

                                        <span className="aaryan-dropdown-toggle"></span>
                                    </li>


                                    {continentsData.map((cnt, index) => (
                                        <li className="hidden-md">
                                            <Link href="#">{cnt.continent}</Link>
                                            <Grid className="aaryan-grid aaryan-grid-lined bgGray minHeight" >
                                                <Grid container className='minHeight'>
                                                    <Grid item xs={12} md={6} position='relative'>
                                                        <List
                                                            sx={{
                                                                py: 3.75,
                                                                pb: 1.25,
                                                                px: 3.12,
                                                                columnCount: 2
                                                            }}>
                                                            {tourTypeData.map((tourtypes, index) => (
                                                                tourtypes.continent === cnt.id && ( // Conditional rendering in JSX
                                                                    <ListItem key={index}> {/* Added key for each list item */}
                                                                        <Link
                                                                            href={`/tour-type/3/${tourtypes.id}`} // Use 'to' for React Router's Link
                                                                           underline="hover"
                                                                            sx={{
                                                                                "&:hover": {
                                                                                    color: GlobalTheme.palette.primary.main,
                                                                                },
                                                                            }}
                                                                        >
                                                                            {tourtypes.tour_type}
                                                                        </Link>
                                                                    </ListItem>
                                                                )
                                                            ))}

                                                        </List>
                                                        <Grid sx={{
                                                            pl: 5,
                                                            mt: 2.12,
                                                            mb: 2.62
                                                        }}>
                                                            <Button {...buttonStyles} href={`/continent/3/${cnt.id}`}>View all</Button>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}
                                                        sx={{
                                                            backgroundColor: GlobalTheme.palette.common.tertiary,
                                                            color: GlobalTheme.palette.common.white
                                                        }}>
                                                        <img src={`https://aaryanholidays.in/express/uploads/${cnt.continent_banner}`} alt='some text' className='borderRadius' />
                                                        <Grid sx={{
                                                            p: 3
                                                        }}>
                                                            <Typography component='h3' variant='h3' sx={{ mt: 1, mb: 2 }}>{cnt.continent_heading}</Typography>
                                                            <Typography variant='p' component='p'>
                                                                {truncateText(cnt.continent_description, 190)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <span className="aaryan-dropdown-toggle"></span>
                                        </li>
                                    ))}
                                </List>
                            </div>
                            <span className="aaryan-dropdown-toggle"></span>
                        </li>

                        <li className="aaryan-menu-mega-blog aaryan-menu-mega-blog-parent">
                            <Link href="#"
                                className={trigger ? 'scrolled' : ''}
                            >Special Package</Link>
                            <div className="minHeight">
                                <List className="aaryan-menu-mega-blog-nav">
                                    {/* <li className="aaryan-active-menu-item">
                                    <Link href="#">Special Packages</Link>

                                    <div className="aaryan-grid aaryan-grid-lined bgGray minHeight">
                                        <div className="aaryan-row">
                                            <Typography variant='h3' component='h2' sx={{ ml: 2, mt: 2 }} gutterBottom>What's Hot</Typography>
                                            {destinationData.map((tour, index) => (
                                                <div className="aaryan-col-3 aaryan-col-3__no-border">
                                                    <Link href="#" className='position-relative'>
                                                        <img src={`${BASE_MEDIA_URL}${tour.src}`} alt='' />
                                                        <span className="aaryan-c-title" dangerouslySetInnerHTML={{ __html: tour.thumbnail_heading }} />
                                                    </Link>
                                                </div>
                                            ))}

                                        </div>
                                    </div>

                                    <span className="aaryan-dropdown-toggle"></span>
                                </li> */}
                                    {tourTypeData.map((country, index) => (
                                        <li className="aaryan-active-menu-item">
                                            <Link href="#">{country.tour_type}</Link>
                                            <Grid className="aaryan-grid aaryan-grid-lined bgGray minHeight" >
                                                <Grid container className='minHeight'>
                                                    <Grid item xs={12} md={6} position='relative'>
                                                        <List
                                                            sx={{
                                                                py: 3.75,
                                                                pb: 1.25,
                                                                px: 3.12,
                                                                columnCount: 2
                                                            }}>
                                                            {splDestinationData.map((spltour, index) => (
                                                                spltour.tour_type_id === country.id && (
                                                                    <ListItem>
                                                                        <Link href={`/tour-details/4/${spltour.id}`} target="_self" underline="hover"color='common.tertiary'
                                                                            sx={{
                                                                                "&:hover": {
                                                                                    color: GlobalTheme.palette.primary.main
                                                                                }
                                                                            }}>
                                                                            {spltour.thumbnail_heading}
                                                                        </Link>
                                                                    </ListItem>
                                                                )
                                                            ))}
                                                        </List>
                                                        <Box sx={{
                                                            pl: 5,
                                                            mt: 2.12,
                                                            mb: 2.62
                                                        }}>
                                                            <Button {...buttonStyles}>View all</Button>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}
                                                        sx={{
                                                            backgroundColor: GlobalTheme.palette.common.tertiary,
                                                            color: GlobalTheme.palette.common.white
                                                        }}
                                                    >
                                                        {country.banner_type === 'video' ? (
                                                            <video
                                                                src={`${BASE_MEDIA_URL}${country.banner_src}`}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={`${BASE_MEDIA_URL}${country.banner_src}`}
                                                                alt=""
                                                            />
                                                        )}
                                                        {/* <img src={`https://aaryanholidays.in/express/uploads/${country.banner_src}`} alt='some text' /> */}
                                                        <Grid sx={{
                                                            p: 3
                                                        }}>
                                                            <Typography component='h3' variant='h3' sx={{ mt: 1, mb: 2 }}>{country.heading}</Typography>
                                                            <Typography variant='p' component='p'>
                                                                {truncateText(country.desc_txt, 190)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <span className="aaryan-dropdown-toggle"></span>
                                        </li>
                                    ))}


                                </List>
                            </div>
                            <span className="aaryan-dropdown-toggle"></span>
                        </li>

                        <li className='aaryan-menu-mega-blog-parent'>
                            <Link href="/about" className={trigger ? 'scrolled' : ''}>About us</Link>
                        </li>


                    </List>
                    <List className="aaryan-menu" id='secB'>
                        <li className='pr-0'>
                            <Logo />
                        </li>
                    </List>
                    <List className="aaryan-menu" id='secC' sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <li className='aaryan-menu-mega-blog-parent'>
                            <Link href="https://aaryanholidays.in/blog" className={trigger ? 'scrolled' : ''}>Blog</Link>
                        </li>

                        <li className='aaryan-menu-mega-blog-parent pr-0'>
                            <Link href="/contact" className={trigger ? 'scrolled' : ''}>Contact us</Link>
                        </li>
                        <li className="aaryan-menu-right">
                            <TravelRequest />
                        </li>
                    </List>
                </Grid >
            )}

            {isMobile && (
                <>
                    <List id='mobileNavSetA'>
                        <ListItem sx={{ py: 0, borderBottom: '1px solid', borderBottomColor: GlobalTheme.palette.common.grayDark }}>
                            <Link href="/" sx={{ color: GlobalTheme.palette.common.black, width: '100%', py: 1.5 }} underline="none"> Home
                            </Link>
                        </ListItem>
                        <Accordion component='li' expanded={expandedParent === 'panel1'} onChange={handleParentChange('panel1')} sx={{
                            ...accordionStyles
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="toursContent"
                                id="toursHeader"
                                sx={{
                                    backgroundColor: expandedParent === 'panel1' ? 'primary.main' : '',
                                    color: expandedParent === 'panel1' ? 'common.white' : ''
                                }}
                            >
                                Tours
                            </AccordionSummary>

                            <AccordionDetails sx={accordionDetailsStyles}>
                                <Accordion expanded={expandedChild === 'panel1A'} onChange={handleChildChange('panel1A')} elevation={0} sx={accordionDetailsStyles}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="popularToursContent"
                                        id="popularToursHeader"
                                        sx={{
                                            px: 2,
                                            backgroundColor: expandedChild === 'panel1A' ? 'common.grayLight' : ''
                                        }}>
                                        Popular Tours
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ px: 2, accordionDetailsStyles }}>
                                        <Typography variant='subtitle1' component='p' fontWeight={600} gutterBottom>What's Hot</Typography>
                                        <List sx={{ columnCount: 2 }}>
                                            {destinationData.map((tour, index) => (
                                                <ListItem id={`desti${index}`} sx={{ p: 0, mb: 2 }}>
                                                    <Link href={`/tour-details/4/${tour.id}`} className='position-relative' sx={{ width: '100%' }}>
                                                        {tour.type === 'video' ? (
                                                            <video
                                                                src={`${BASE_MEDIA_URL}${tour.src}`}
                                                                className={NavbarStyle.mobileLockHeight}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={`${BASE_MEDIA_URL}${tour.src}`}
                                                                alt={tour.alt || 'Tour Image'}
                                                                className={NavbarStyle.mobileLockHeight}
                                                            />
                                                        )}
                                                        <span className="aaryan-c-title borderRadius" dangerouslySetInnerHTML={{ __html: tour.thumbnail_heading }} />
                                                    </Link>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>

                                {continentsData.map((cnt, index) => (
                                    <Accordion key={index} expanded={expandedChild === `panel1A${index}`} onChange={handleChildChange(`panel1A${index}`)} sx={{
                                        ...accordionStyles
                                    }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel1A${index}-content`}
                                            id={`panel1A${index}-header`}
                                            sx={{
                                                backgroundColor: expandedChild === `panel1A${index}` ? 'common.grayLight' : ''
                                            }}>
                                            {cnt.continent}
                                        </AccordionSummary>
                                        <AccordionDetails sx={accordionDetailsStyles}>
                                            <Grid item xs={12} md={6}
                                                sx={{
                                                    backgroundColor: GlobalTheme.palette.common.tertiary,
                                                    color: GlobalTheme.palette.common.white
                                                }}>
                                                <img src={`https://aaryanholidays.in/express/uploads/${cnt.continent_banner}`} alt='some text' className='borderRadius' style={{ height: 150, width: '100%' }} />
                                                <Grid sx={{
                                                    p: 3
                                                }}>
                                                    <Typography component='h3' variant='h3' sx={{ mt: 1, mb: 2 }}>{cnt.continent_heading}</Typography>
                                                    <Typography variant='p' component='p'>
                                                        {truncateText(cnt.continent_description, 80)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <List
                                                sx={{
                                                    mt: 2,
                                                    p: 0,
                                                    columnCount: 2
                                                }}>
                                                {tourTypeData.map((tourtypes, index) => (
                                                    tourtypes.continent === cnt.id && (
                                                        <ListItem key={index}>
                                                            <Link
                                                                href={`/tour-type/3/${tourtypes.id}`} // Use 'to' for React Router's Link
                                                                underline="hover"
                                                                sx={{
                                                                    color: GlobalTheme.palette.common.black,

                                                                    "&:hover": {
                                                                        color: GlobalTheme.palette.primary.main,
                                                                    },
                                                                }}
                                                            >
                                                                {tourtypes.tour_type}
                                                            </Link>
                                                        </ListItem>
                                                    )
                                                ))}
                                            </List>
                                            <Box sx={gridStyles}>
                                                <Link {...buttonStyles} href={`/continent/3/${cnt.id}`}>View all1</Link>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion component='li' expanded={expandedParent === 'panel2'} onChange={handleParentChange('panel2')} sx={accordionStyles}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="specialPackage"
                                id="specialPackageHeader"
                                sx={{
                                    backgroundColor: expandedParent === 'panel2' ? 'primary.main' : '',
                                    color: expandedParent === 'panel2' ? 'common.white' : ''
                                }}
                            >
                                Special Package
                            </AccordionSummary>

                            <AccordionDetails sx={accordionDetailsStyles}>
                                {tourTypeData.map((country, index) => (
                                    <Accordion key={index} expanded={expandedChild === `panel2A${index}`} onChange={handleChildChange(`panel2A${index}`)} sx={accordionStyles}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel2A${index}-content`}
                                            id={`panel2A${index}-header`}
                                            sx={{
                                                backgroundColor: expandedChild === `panel2A${index}` ? 'common.grayLight' : ''
                                            }}>
                                            {country.tour_type}
                                        </AccordionSummary>
                                        <AccordionDetails sx={accordionDetailsStyles}>
                                            <Grid item xs={12} md={6}
                                                sx={{
                                                    backgroundColor: GlobalTheme.palette.common.tertiary,
                                                    color: GlobalTheme.palette.common.white
                                                }}>
                                                {country.banner_type === 'video' ? (
                                                    <video
                                                        src={`${BASE_MEDIA_URL}${country.banner_src}`}
                                                        autoPlay
                                                        style={{ height: 150, width: '100%', objectFit: 'cover' }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={`${BASE_MEDIA_URL}${country.banner_src}`}
                                                        alt=""
                                                        style={{ height: 150, width: '100%', objectFit: 'cover' }}
                                                    />
                                                )}
                                                <Grid sx={{
                                                    p: 3
                                                }}>
                                                    <Typography component='h3' variant='h3' sx={{ mt: 1, mb: 2 }}>{country.heading}</Typography>
                                                    <Typography variant='p' component='p'>
                                                        {truncateText(country.desc_txt, 80)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <List
                                                sx={{
                                                    mt: 2,
                                                    p: 0,
                                                    columnCount: 2
                                                }}>
                                                {splDestinationData.map((spltour, index) => (
                                                    spltour.tour_type_id === country.id && (
                                                        <ListItem key={index}>
                                                            <Link
                                                                href={`/tour-details/4/${spltour.id}`}
                                                                underline="hover"
                                                                sx={{
                                                                    color: GlobalTheme.palette.common.black,

                                                                    "&:hover": {
                                                                        color: GlobalTheme.palette.primary.main,
                                                                    },
                                                                }}
                                                            >
                                                                {spltour.thumbnail_heading}
                                                            </Link>
                                                        </ListItem>
                                                    )
                                                ))}
                                            </List>
                                            <Grid sx={{
                                                pl: 2,
                                                mb: 2.62
                                            }}>
                                                <Button {...buttonStyles}>View all</Button>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </AccordionDetails>
                        </Accordion>

                        {linkItems.map((item, index) => (
                            <ListItem key={index} sx={{ py: 0, borderBottom: '1px solid', borderBottomColor: GlobalTheme.palette.common.grayDark }}>
                                <Link href={item.href} sx={{ color: GlobalTheme.palette.common.black, width: '100%', py: 1.5 }} underline="none">{item.label}</Link>
                            </ListItem>
                        ))}
                    </List >

                    <Paper id='mobileNavSetB' sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                        <BottomNavigation
                            showLabels
                            sx={{ justifyContent: 'space-between' }}
                        >
                            <BottomNavigationAction label="Call Us" icon={<SupportAgentOutlinedIcon />} />
                            <BottomNavigationAction label="Email Us" icon={<MailOutlinedIcon />} />
                            <TravelRequest size='medium' />
                        </BottomNavigation>

                    </Paper>
                </>
            )
            }
        </>
    );
};


export default MenuComponent;