import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Navbar from './components/navbar/navbar';
import Home from './pages/home/landing';
import Footer from './components/footer/footer';

import TourListing from './pages/tourListing/tourListing';
import TourDetails from './pages/tourDetails/tourDetails';
import About from './pages/about/about';
import HotelsDetails from './pages/hotels/hotelsDetails';
import Blog from './pages/blog/index';
import Contact from './pages/contact/contact';
import Login from './pages/login/login';
import Signup from './pages/login/signup';
import { ThemeProvider } from '@mui/material/styles';
import { ResponsiveGlobalTheme } from './theme';
import SearchResultPage from './components/SearchResultPage';
import TourListingContinent from './pages/tourListing/tourListing-continent';
import Profile from './pages/profile/profile';

const App = () => {
  const location = useLocation();

  const isLoginPage = location.pathname === "/login";

  return (
    <>
      <ThemeProvider theme={ResponsiveGlobalTheme}>

        {!['/login', '/signup'].includes(window.location.pathname) && <Navbar />}

        <main aria-labelledby='mantleText'>

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/tour-type/:banner_for/:refer_other_id?" element={<TourListing />} />
            <Route path="/continent/:banner_for/:refer_other_id?" element={<TourListingContinent />} />
            <Route path="/tour-details/:banner_for/:refer_other_id?" element={<TourDetails />} />
            <Route path="/searchresultpage" element={<SearchResultPage />} />
            <Route path="/about" element={<About />} />
            <Route path='/hotels/:id' element={<HotelsDetails />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/profile' element={<Profile />} />
          </Routes>

        </main>

        {!['/login', '/signup'].includes(window.location.pathname) && <Footer />}
      </ThemeProvider>
    </>
  );
}

export default App;
