import React, { useState } from 'react';
import { Container, Grid, TextField, Button, Typography, Box, alpha, FormControl, FormHelperText } from '@mui/material';
import Person3OutlinedIcon from '@mui/icons-material/Person3Outlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';

import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import HomeMantle from './../../components/mantles/mantle';
import { GlobalTheme } from './../../theme';

const saleOffice = [
    {
        id: 1,
        slNo: 1,
        addresss: '104 Lawrence Road, 1st Flr, Opp Nehru Shopping complex, Amritsar - 143001, Punjab, India'
    },
    {
        id: 2,
        slNo: 2,
        addresss: '1st Flr, Plot - 1616, Lane - 09, Palaspalli, Near Airport, Bhubaneswar - 751020,  Odissha, India'
    },
    {
        id: 3,
        slNo: 3,
        addresss: 'A wing 305, Bhoomi classic, Link Road, Malad West, Mumbai - 400064, Maharahtra, India'
    }

]

const ContactUs = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [msg, setMsg] = useState('');
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!name.trim()) {
            formErrors.name = "Name is required";
            isValid = false;
        }

        if (!email.trim()) {
            formErrors.email = "Email is required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            formErrors.email = "Email is invalid";
            isValid = false;
        }

        if (!phone.trim()) {
            formErrors.phone = "Phone number is required";
            isValid = false;
        } else if (!/^\d{10}$/.test(phone)) {
            formErrors.phone = "Phone number must be 10 digits";
            isValid = false;
        }

        if (!msg.trim()) {
            formErrors.msg = "Message is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) return;

        const formData = {
            name,
            email,
            phone,
            msg
        };

        try {
            const response = await fetch('https://aaryanholidays.in/frontend/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                alert('Your message has been sent successfully!');
                setName('');
                setEmail('');
                setPhone('');
                setMsg('');
            } else {
                alert('Failed to send message. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again later.');
        }
    };
    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                <Card id="headerContainer"
                    sx={{
                        position: 'relative',
                        zIndex: 1,
                        maxHeight: '500px'
                    }}>

                    <Grid
                        sx={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            textAlign: 'center',
                            left: 0,
                            bottom: 0,
                            top: 0,
                            backgroundColor: alpha(GlobalTheme.palette.common.black, .65),
                            zIndex: 2,
                        }}>
                        <CardContent id="mantleText"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%',
                                flexDirection: 'column',
                                flexWrap: 'wrap',
                                alignContent: 'center',
                                pl: 6,
                                pr: 8,
                                mt: 10
                            }}>
                            <Typography id="mantleHeading" gutterBottom variant='h1' color='white' >Contact Us</Typography>
                            <Typography id="mantleSubHeading" component='p' variant="subtitle1" color='white' gutterBottom
                                sx={{
                                    mb: 2.5,
                                    px: 35
                                }}>
                                Contact Us

                            </Typography>
                        </CardContent>
                    </Grid>

                    <Grid
                        sx={{
                            background: 'linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.1) 100%)',
                        }}
                        width='100%'
                        height='100%'
                        position='absolute'>
                    </Grid>


                    <CardMedia
                        id="mantleMedia"
                        component="img"
                        image='https://as2.ftcdn.net/v2/jpg/07/31/57/55/1000_F_731575547_gGuMdwlD1RxU3Vdpz9789NCbqXxCjBaP.jpg'
                        alt=''
                    />

                </Card>

                <Container id="contact" sx={{ backgroundColor: GlobalTheme.palette.common.grayLight, pb: 1 }} maxWidth='xxl'>
                    <Container maxWidth="lg" sx={{ mt: 4 }} component='section'>

                        <Grid container spacing={4}>
                            <Grid item xs={12} md={8} >

                                <Typography variant='h2' component='h2' sx={{ mb: 3 }}>Connect with us for your holidays planning</Typography>
                                <Typography variant='subtitle2' component='p' sx={{ color: GlobalTheme.palette.common.bodyTxt }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Typography>

                                <Grid item xs sx={{
                                    backgroundColor: GlobalTheme.palette.common.white,
                                    mt: 3,
                                    py: 3,
                                    px: 5,
                                    border: '1px solid',
                                    borderColor: GlobalTheme.palette.common.grayDark,
                                    borderRadius: 2
                                }}>

                                    <Typography variant="h3" component='h3' gutterBottom>Contact Us</Typography>
                                    <Typography variant='subtitle2' component='p' sx={{ color: GlobalTheme.palette.common.bodyTxt }}>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </Typography>

                                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                        <Stack direction='row' spacing={6} sx={{ mt: 8 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                                                <Person3OutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                                <FormControl fullWidth variant="standard" error={Boolean(errors.name)}>
                                                    <TextField
                                                        id="fName"
                                                        required
                                                        label="First Name"
                                                        placeholder='Enter your first name'
                                                        variant="standard"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                    {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
                                                </FormControl>
                                            </Box>

                                            <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                                                <Person3OutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                                <FormControl fullWidth variant="standard" error={Boolean(errors.name)}>
                                                    <TextField
                                                        id="lName"
                                                        required
                                                        label="Last Name"
                                                        placeholder='Enter your last name'
                                                        variant="standard"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                    {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
                                                </FormControl>
                                            </Box>

                                        </Stack>

                                        <Stack direction='row' spacing={6} sx={{ mt: 5 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                                                <PhoneIphoneOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                                <FormControl fullWidth variant="standard" error={Boolean(errors.phone)}>
                                                    <TextField
                                                        id="phone"
                                                        required
                                                        label="Mobile"
                                                        placeholder='Enter your Phone number'
                                                        variant="standard"
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                    />
                                                    {errors.phone && <FormHelperText>{errors.phone}</FormHelperText>}
                                                </FormControl>
                                            </Box>

                                            <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                                                <EmailOutlinedIcon sx={{ color: 'action.active', mr: 1, my: .5 }} />
                                                <FormControl fullWidth variant="standard" error={Boolean(errors.email)}>
                                                    <TextField
                                                        id="email"
                                                        required
                                                        label="Email ID"
                                                        placeholder='Enter your email'
                                                        variant="standard"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                    {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
                                                </FormControl>
                                            </Box>

                                        </Stack>

                                        <Box sx={{ display: 'flex', width: '100%', mt: 5 }}>
                                            <ChatOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                            <FormControl fullWidth variant="standard" error={Boolean(errors.msg)}>
                                                <TextField
                                                    id="msg"
                                                    required
                                                    multiline
                                                    minRows={3}
                                                    maxRows={6}
                                                    label="Enquiry"
                                                    placeholder='What you would like to enquire about'
                                                    variant="standard"
                                                    value={msg}
                                                    onChange={(e) => setMsg(e.target.value)}
                                                />
                                                {errors.msg && <FormHelperText>{errors.msg}</FormHelperText>}
                                            </FormControl>
                                        </Box>
                                        <Stack direction='row' justifyContent='end' spacing={3} sx={{ mt: 8 }}>
                                            <Button variant="text" type="button">
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                sx={{
                                                    backgroundColor: GlobalTheme.palette.common.black,
                                                    color: GlobalTheme.palette.common.white,
                                                    px: 3, py: 1.5
                                                }}
                                            >
                                                Send Message
                                            </Button>
                                        </Stack>
                                    </form>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Typography variant="h3" component='h3' gutterBottom>Locate Us</Typography>
                                    <Stack direction='row' spacing={5} sx={{ width: '100%' }}>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    p: 2,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    backgroundColor: GlobalTheme.palette.common.white,
                                                    height: '100%',
                                                    borderRadius: 2,
                                                    width: '100%'
                                                }}
                                                onClick={() => window.location.href = 'tel:+913340515000'}

                                            >
                                                <Stack direction='row'>
                                                    <CallOutlinedIcon sx={{ mr: 1 }} />
                                                    <Typography>Call Us</Typography>
                                                </Stack>
                                                {/* <Grid item xs>
                                                    91 33 4051 5000
                                                </Grid> */}
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    p: 2,
                                                    maxWidth: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexWrap: 'wrap',
                                                    backgroundColor: GlobalTheme.palette.common.white,
                                                    height: '100%',
                                                    borderRadius: 2,
                                                    width: '100%'

                                                }}
                                                onClick={() => window.location.href = 'mailto:info@example.com'}
                                            >
                                                <Stack direction='row'>
                                                    <EmailOutlinedIcon sx={{ mr: 1 }} />
                                                    <Typography>Email Us</Typography>
                                                </Stack>
                                                {/* <Grid item xs>
                                                    tours.ccu@aaryanholidays.com
                                                </Grid> */}


                                            </Button>
                                        </Grid>
                                    </Stack>
                                    <Box
                                        sx={{
                                            mt: 4,
                                            backgroundColor: GlobalTheme.palette.common.white,
                                            color: GlobalTheme.palette.primary.main,
                                            border: '1px solid',
                                            borderColor: GlobalTheme.palette.primary.main,
                                            width: '100%',
                                            height: '100%',
                                            px: 3,
                                            py: 4,
                                            textAlign: 'center'
                                        }} borderRadius={2}>
                                        <AccountBalanceOutlinedIcon sx={{ fontSize: '10rem' }} />
                                        <Typography variant="h4" component='h4' sx={{ mb: 2 }}>Head & Corporate Office</Typography>
                                        <Typography variant="subtitle2" sx={{ color: GlobalTheme.palette.common.bodyTxt }}>
                                            7/1C, Hazra Road, Edcon Swastika Building, 1st Floor, Kolkata - 700 026 (W.B.) INDIA
                                        </Typography>

                                        <Button sx={{ flexDirection: 'column', mt: 5 }}>
                                            <LocationOnOutlinedIcon sx={{ fontSize: GlobalTheme.typography.h1.fontSize }} />
                                            See on the map
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container
                            sx={{
                                borderRadius: 2,
                                backgroundColor: GlobalTheme.palette.common.white,
                                color: GlobalTheme.palette.primary.main,
                                border: '1px solid',
                                borderColor: GlobalTheme.palette.primary.main,
                                mt: 3.62,
                                mb: 7.72,
                                px: 6,
                                py: 5
                            }}>
                            <Stack direction='row' alignItems='center'>
                                <List sx={{
                                    display: 'inline-flex'
                                }}>
                                    {saleOffice.map((item, index) => (
                                        <ListItem key={index} id={item.id} >
                                            <Typography component='span' variant='h2' sx={{
                                                backgroundColor: GlobalTheme.palette.primary.main,
                                                color: GlobalTheme.palette.common.white,
                                                borderRadius: 12.5,
                                                width: '160px',
                                                height: '62px',
                                                mr: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                            >
                                                {item.slNo}
                                            </Typography>
                                            {item.addresss}
                                        </ListItem>
                                    ))}
                                </List>
                            </Stack>
                        </Grid>
                    </Container>
                </Container>
            </ThemeProvider >
        </React.Fragment >
    );
};

export default ContactUs;
