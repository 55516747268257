import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Virtual, Navigation, FreeMode, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';

import { CardActionArea } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { alpha } from '@mui/system';

import { GlobalTheme } from '../../theme';
import DestinationStyle from './assets/scss/destination.module.scss';

const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';

const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};

const Carousel = () => {
    const [destinationData, setDestinationData] = useState([]);
    const videoRefs = useRef([]);
    const [swiperRef, setSwiperRef] = useState(null);
    const navigate = useNavigate();

    const handleMouseEnter = (index) => {
        const videoElement = videoRefs.current[index];
        if (videoElement && videoElement.paused) {
            videoElement.play().catch(error => console.error('Error playing video:', error));
        }
    };

    const handleMouseLeave = (index) => {
        const videoElement = videoRefs.current[index];
        if (videoElement && videoElement.tagName === 'VIDEO' && !videoElement.paused) {
            videoElement.pause();
            videoElement.currentTime = 0;
        }
    };

    const handleCardClick = (id) => {
        navigate(`/tour-details/4/${id}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const destinationsResponse = await fetch('https://aaryanholidays.in/frontend/alldestinations');
                const destinationsData = await destinationsResponse.json();
                const promotedData = destinationsData.filter(destination => destination.promote === 1);
                setDestinationData(promotedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item xs={12} lg={2} sx={{ mb: 4, textAlign: 'left' }}>
                <Typography id="destinationHeading" component='h2' variant='h2' sx={{ mb: 2, fontWeight: 600 }}>Promotions</Typography>
                <Typography component='p'>Lorem ipsum dolor sit amet consectetur. Id aliquet diam quis consectetur quisque sodales quam nullam. Condimentum cras diam sed sem mattis viverra pharetra a.</Typography>
            </Grid>

            <Grid item xs={12} lg={10} sx={{ overflow: 'hidden' }}>
                <Swiper
                    keyboard={{ enabled: true }}
                    modules={[Virtual, Navigation, FreeMode, Keyboard]}
                    onSwiper={setSwiperRef}
                    slidesPerView={3}
                    centeredSlides={false}
                    spaceBetween={50}
                    navigation={{
                        nextEl: '.custom-swiper-button-next',
                        prevEl: '.custom-swiper-button-prev',
                    }}
                    virtual
                    grabCursor={true}
                    // freeMode={true}
                    // loop={true}
                    //  slidesOffsetAfter={10}

                    className={DestinationStyle.swiperContainer}
                    breakpoints={{
                        600: { slidesPerView: 2, spaceBetween: 20 },
                        900: { slidesPerView: 4, spaceBetween: 40 },
                        1200: { slidesPerView: 5, spaceBetween: 50 },
                    }}
                    style={{
                        width: '100%'
                    }}
                >
                    {destinationData.map((tour, index) => (
                        <SwiperSlide
                            key={index}
                            virtualIndex={index}
                            className={DestinationStyle.swiperSlideCustom}
                        >
                            <Card
                                key={index}
                                sx={{
                                    position: 'relative',
                                    borderRadius: 2,
                                    width: 258,
                                    height: 348,
                                    objectFit: 'cover',
                                    overflow: 'hidden'
                                }}
                            >
                                <CardActionArea
                                    id={'destination' + tour.id}
                                    onClick={() => handleCardClick(tour.id)}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={() => handleMouseLeave(index)}
                                    sx={{ height: 'inherit' }}
                                >
                                    {tour.type === 'video' ? (
                                        <CardMedia
                                            id={`destinationThumbsVid${index}`}
                                            component="video"
                                            src={`${BASE_MEDIA_URL}${tour.src}`}
                                            loop={true}
                                            muted
                                            playsInline
                                            ref={(el) => (videoRefs.current[index] = el)}
                                        >
                                            <source src={`${BASE_MEDIA_URL}${tour.src}`} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </CardMedia>
                                    ) : (
                                        <CardMedia
                                            id={`destinationThumbsImg${index}`}
                                            component="img"
                                            src={`${BASE_MEDIA_URL}${tour.src}`}
                                            alt={tour.alt}
                                        />
                                    )}
                                    <CardContent
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            textAlign: 'left',
                                            height: '100%',
                                            width: '100%',
                                            pt: 35,
                                            transition: 'all .4s ease-in',
                                            "&:hover": {
                                                pt: 23,
                                                backgroundColor: alpha(GlobalTheme.palette.common.tertiary, 0.5)
                                            }
                                        }}
                                    >
                                        <Typography variant='h3' component='h3' sx={{ color: GlobalTheme.palette.common.white, mb: 2 }} gutterBottom>
                                            <span dangerouslySetInnerHTML={{ __html: tour.thumbnail_heading }} />
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: GlobalTheme.palette.common.white }} >
                                            {truncateText(tour.thumbnail_desc, 90)}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                    ))}

                    <IconButton
                        className="custom-swiper-button-prev"
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: 0,
                            transform: 'translateY(-50%)',
                            zIndex: 10,
                            color: GlobalTheme.palette.primary.main,
                            "&:hover, &:focus": {
                                boxShadow: GlobalTheme.shadows[5]
                            }
                        }}
                    >
                        <ArrowBackIcon sx={{ fontSize: GlobalTheme.typography.h1.fontSize }} />
                    </IconButton>
                    <IconButton
                        className="custom-swiper-button-next"
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: 0,
                            transform: 'translateY(-50%)',
                            zIndex: 10,
                            color: GlobalTheme.palette.primary.main,
                            "&:hover, &:focus": {
                                boxShadow: GlobalTheme.shadows[5]
                            }
                        }}
                    >
                        <ArrowForwardIcon sx={{ fontSize: GlobalTheme.typography.h1.fontSize }} />
                    </IconButton>
                </Swiper>
            </Grid>
        </Grid >
    );
};

export default Carousel;
