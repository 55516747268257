import { useGoogleLogin } from '@react-oauth/google';
import Button from '@mui/material/Button';
import axios from 'axios';
import { GlobalTheme } from './../../theme';
import { ReactComponent as GoogleLogo } from './gIcon.svg';

const GoogleLoginButton = () => {
  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      const accesstoken = tokenResponse.access_token;

      const result = axios.post('https://aaryanholidays.in/backend/google', { id_token: accesstoken });

      // Handle the result from the backend
      console.log(result);
    },
  });

  return (
    <>
      <Button onClick={() => login()}
        fullWidth
        size="large"
        variant="outlined"
        sx={{
          
          my: 2,
          py: 2,
          boxShadow: 'none',
          borderRadius: 10,
          borderColor: GlobalTheme.palette.common.grayDark,
          color: GlobalTheme.palette.common.bodyTxt
        }}
      >
        <GoogleLogo style={{width: 30, marginRight: 10}}/> Sign in with Google </Button>
    </>
  );
};

export default GoogleLoginButton;


