import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId, showControl, mute }) => (
    <div style={{ width: '100%', height: '400px', backgroundColor: '#f0f0f0' }}>
        <iframe
            title="YouTube Video Player"
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${embedId}?&controls=${showControl}&mute=${mute}&disablekb=1`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen 
            
        />
    </div>
);

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;
