import React, { useState, useEffect } from 'react';
import { ThemeProvider, alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import List from '@mui/material/List';
import KitesurfingOutlinedIcon from '@mui/icons-material/KitesurfingOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import Link from '@mui/material/Link';
import Logo from '../logo/logo';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar'; // Import Snackbar

import Logout from '@mui/icons-material/Logout';
import { GlobalTheme } from '../../theme';
import NavList from './navlists';
import { useNavigate } from 'react-router-dom';

const drawerWidth = '100%';

function DrawerAppBar(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const isMobile = useMediaQuery(GlobalTheme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(GlobalTheme.breakpoints.up('md'));
  const { window } = props;
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const container = window !== undefined ? () => window().document.body : undefined;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
    target: window ? window() : undefined,
  });

  useEffect(() => {
    // Check localStorage for user info
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
    googleLogout();

    // Open snackbar on logout
    setSnackbarOpen(true);
  };

  // Close the snackbar after a few seconds or manually
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={GlobalTheme}>
        <CssBaseline />
        <Box sx={{ display: 'flex' }}>
          <AppBar
            sx={{
              width: '100%',
              backgroundColor: {
                xs: GlobalTheme.palette.common.white,
                md: trigger ? GlobalTheme.palette.common.white : 'transparent',
              },
              position: {
                xs: 'relative',
                md: 'fixed',
              },
              top: 0,
              boxShadow: trigger ? GlobalTheme.shadows[4] : 'none',
              transition: 'position .5s ease-in-out, box-shadow 0.3s ease-in-out, opacity 0.6s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out',

              ...(isDesktop && {
                '.navPrimary': {
                  transition: 'background-color 0.3s ease-in-out',
                },

                '.navSecondary': {
                  transition: 'background-color 0.3s ease-in-out',
                  transitionDelay: '.2s',
                  boxShadow: 'none',
                },

                '&:hover': {
                  '.navPrimary': {
                    backgroundColor: alpha(GlobalTheme.palette.common.white, 0.8),
                    'a': {
                      color: GlobalTheme.palette.common.black,
                    },
                  },

                  '.navSecondary': {
                    backgroundColor: alpha(GlobalTheme.palette.common.white, 0.2),
                  },
                },
              }),
            }}
          >
            <Toolbar
              sx={{
                justifyContent: 'space-between',
                px: { md: 0, width: '100%' },
              }}
              id="navbar"
              {...(isDesktop && {
                component: 'nav',
                'aria-label': 'primary navigation',
              })}
            >
              {isMobile && (
                <>
                  <Grid
                    id="menuPrimaryMobile"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      width: '100%',
                      display: 'flex',
                      py: 1.5,
                    }}
                  >
                    <Grid>
                      <Logo />
                    </Grid>

                    <Grid>
                      <IconButton
                        id="mobileMenu"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                      >
                        <MenuIcon />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Drawer
                    container={container}
                    variant="temporary"
                    anchor="top"
                    open={open}
                    id="navMobile"
                    ModalProps={{
                      keepMounted: true,
                    }}
                    sx={{
                      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, height: '100%' },
                    }}
                  >
                    <Box component="nav" aria-label="mobile navigation">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                          p: 2,
                        }}
                      >
                        <Grid>
                          <Logo />
                        </Grid>
                        <Stack direction="row" spacing={3} alignItems="center">
                          <Link
                            href="/login"
                            variant="outlined"
                            disableFocusRipple
                            sx={{
                              color: GlobalTheme.palette.primary.main,
                            }}
                          >
                            Sign in
                          </Link>

                          <IconButton onClick={handleDrawerClose}>
                            <CloseOutlinedIcon />
                          </IconButton>
                        </Stack>
                      </Stack>
                      <Divider />
                      <NavList />
                    </Box>
                  </Drawer>
                </>
              )}

              {isDesktop && (
                <Grid
                  sx={{
                    width: '100%',
                    display: 'block',
                  }}
                >
                  <Grid
                    id="navSecondary"
                    alignItems="center"
                    justifyContent="space-between"
                    className="navSecondary"
                    sx={{
                      display: {
                        xs: 'none',
                        md: trigger ? 'none' : 'flex',
                      },
                      transition: 'display .3s fadeOut',
                      boxShadow: `0 0 0 1px ${alpha(GlobalTheme.palette.common.white, 0.2)}`,
                    }}
                  >
                    <List sx={{ display: 'inline-flex' }}>
                      <ListItem disablePadding>
                        <ListItemButton
                          component="a"
                          href="tel:+91 033 4051 5000"
                          sx={{
                            color: GlobalTheme.palette.common.white,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 'auto',
                              color: GlobalTheme.palette.common.grayDark,
                              mr: 1,
                            }}
                          >
                            <PhoneOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary="91 33 4051 5000" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton
                          component="a"
                          href="mailto:tours.ccu@aaryanholidays.com"
                          sx={{
                            color: GlobalTheme.palette.common.white,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: GlobalTheme.palette.common.grayDark,
                              minWidth: 'auto',
                              mr: 1,
                            }}
                          >
                            <MailOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary="tours.ccu@aaryanholidays.com" />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List sx={{ display: 'inline-flex' }}>
                      <ListItem
                        sx={{
                          px: 1.5,
                          py: 0,
                        }}
                      >
                        <Link href="https://www.facebook.com" target="_blank">
                          <FacebookIcon
                            sx={{
                              color: trigger ? GlobalTheme.palette.common.black : GlobalTheme.palette.common.white,
                            }}
                          />
                        </Link>
                      </ListItem>
                      <ListItem
                        sx={{
                          px: 1.5,
                          py: 0,
                        }}
                      >
                        <Link href="https://www.instagram.com" target="_blank">
                          <InstagramIcon
                            sx={{
                              color: trigger ? GlobalTheme.palette.common.black : GlobalTheme.palette.common.white,
                            }}
                          />
                        </Link>
                      </ListItem>
                      <ListItem sx={{ px: 1.5, py: 0 }}>
                        {!user ? (
                          <Link
                            href="/login"
                            variant="outlined"
                            disableFocusRipple
                            sx={{
                              minWidth: 50,
                              color: {
                                xs: GlobalTheme.palette.common.white,
                                md: GlobalTheme.palette.common.white,
                              },
                            }}
                          >
                            Sign in
                          </Link>
                        ) : (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="Account settings">
                              <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={openMenu ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openMenu ? 'true' : undefined}
                              >
                                <Avatar sx={{ width: 32, height: 32, backgroundColor: '#B71C1C' }}>
                                  <Typography variant="body1" sx={{ color: GlobalTheme.palette.common.white }}>
                                    {user.firstName.charAt(0)}
                                    {user.lastName.charAt(0)}
                                  </Typography>
                                </Avatar>
                              </IconButton>
                            </Tooltip>

                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={openMenu}
                              onClose={handleClose}
                              onClick={handleClose}
                              slotProps={{
                                paper: {
                                  elevation: 0,
                                  sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    '&::before': {
                                      content: '""',
                                      display: 'block',
                                      position: 'absolute',
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: 'background.paper',
                                      transform: 'translateY(-50%) rotate(45deg)',
                                      zIndex: 0,
                                    },
                                  },
                                },
                              }}
                              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                              <MenuItem onClick={handleClose}>
                                <ManageAccountsOutlinedIcon fontSize="small" sx={{ mr: 2 }} />
                                Profile
                              </MenuItem>
                              <MenuItem onClick={handleClose}>
                                <KitesurfingOutlinedIcon fontSize="small" sx={{ mr: 2 }} />
                                My Tour
                              </MenuItem>
                              <Divider />
                              <MenuItem onClick={handleLogout} sx={{ mb: 1 }}>
                                <ListItemIcon>
                                  <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                              </MenuItem>
                            </Menu>
                          </Box>
                        )}
                      </ListItem>
                    </List>
                  </Grid>

                  <Grid
                    id="navPrimary"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    className="navPrimary"
                    sx={{ display: 'flex', width: '100%' }}
                  >
                    <NavList />
                  </Grid>
                </Grid>
              )}
            </Toolbar>
          </AppBar>
        </Box>

        {/* Snackbar for logout message */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
          message="Logout successful"
        />
      </ThemeProvider>
    </React.Fragment>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
