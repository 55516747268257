import React, { useRef, useState } from 'react';
import { Virtual, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Stack from '@mui/material/Stack';

import Hotels from '../../components/hotels/hotels';
import ShoppingMall from '../hotels/shoppingmall';
import KidsArena from '../hotels/kidsarena';
function HotelsShoppingKidsTab(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`HotelsShoppingKids-tabpanel-${index}`}
            aria-labelledby={`HotelsShoppingKids-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

HotelsShoppingKidsTab.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `HotelsShoppingKids-tab-${index}`,
        'aria-controls': `HotelsShoppingKids-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [swiperRef, setSwiperRef] = useState(null);
    const [slides, setSlides] = useState(
        Array.from({ length: 5 }).map((_, index) => `Slide ${index + 1}`)
    );

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="view hotels, experience shopping and kids play zone">
                    <Tab label="Hotels" {...a11yProps(0)} />
                    <Tab label="Shopping" {...a11yProps(1)} />
                    <Tab label="Kids area" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <HotelsShoppingKidsTab value={value} index={0}>
                <Hotels />
            </HotelsShoppingKidsTab>
            <HotelsShoppingKidsTab value={value} index={1}>
            <ShoppingMall />
            </HotelsShoppingKidsTab>
            <HotelsShoppingKidsTab value={value} index={2}>
               <KidsArena/>
            </HotelsShoppingKidsTab>
        </Box>
    );
}
