import * as React from 'react';
import LogoStyle from './assets/scss/logo.module.scss';
import { Link } from 'react-router-dom';

const Logo = ({ fillColor = "#0ABAB5" }) => (
    <Link to={"/"} className={`${LogoStyle.logo} ${LogoStyle.pNone}`}>
        <svg width="auto" height="auto" viewBox="0 0 319 248" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M296.472 89.4174L289.181 89.8652L288.93 85.3869L296.22 84.939C297.377 84.8893 298.383 85.7352 298.483 86.9294V87.1782C298.533 88.3227 297.628 89.3178 296.472 89.4174Z" fill={fillColor} />
            <path d="M296.472 103.649L289.181 103.251L288.93 107.729L296.22 108.127C297.377 108.177 298.383 107.281 298.483 106.137V105.888C298.584 104.694 297.679 103.698 296.472 103.649Z" fill={fillColor} />
            <path d="M281.639 118.825L297.779 101.559C300.243 101.708 318.194 100.315 318.093 96.6824C317.942 91.5572 297.729 91.5074 297.729 91.5074L281.488 74.3405C280.533 73.9424 279.929 73.9424 279.577 74.241C278.873 74.8381 279.426 76.8782 281.287 80.3613C283.6 84.2923 285.913 88.2731 288.226 92.204L271.683 93.3983L262.934 88.2233L261.426 90.0644L263.94 94.1446C260.923 94.8413 259.012 95.9857 259.062 96.8814C259.113 97.8268 261.174 98.7722 264.191 99.1703L261.878 103.499L263.185 105.141L272.085 99.7674C277.465 100.166 282.846 100.514 288.276 100.912C285.963 104.893 283.7 108.873 281.387 112.854C279.577 116.337 279.024 118.377 279.728 119.024C280.08 119.273 280.683 119.223 281.639 118.825Z" fill={fillColor} />
            <path d="M281.488 74.3405C280.533 73.9424 279.929 73.9424 279.577 74.241C278.873 74.8381 279.426 76.8782 281.287 80.3613C281.488 80.7097 281.689 81.058 281.89 81.4063L288.477 81.7546L281.488 74.3405Z" fill={fillColor} />
            <path d="M281.639 118.825C280.684 119.223 280.08 119.273 279.728 118.974C279.024 118.377 279.577 116.337 281.388 112.804C281.589 112.456 281.79 112.108 281.991 111.759L288.578 111.361L281.639 118.825Z" fill={fillColor} />
            <path d="M209.736 130.568C194.45 138.231 176.651 148.432 158.549 161.518C173.282 204.759 187.612 246.756 188.014 248H252.626L209.736 130.568ZM161.968 0H91.3226L17.5091 201.724C26.5095 190.528 37.8229 177.989 51.6001 165.449C65.9303 152.413 84.0317 138.48 106.055 126.09C117.218 93.2986 126.268 66.7769 126.268 66.7769C126.268 66.7769 132.403 84.7897 140.8 109.47C157.544 102.753 175.997 97.2295 196.16 93.5474L161.968 0Z" fill={fillColor} />
            <path d="M252.626 100.066C252.626 100.066 252.626 100.066 252.526 100.066C248.654 101.211 124.308 139.028 64.5228 247.95H0.614746C0.614746 248 73.9254 100.066 252.626 100.066Z" fill={fillColor} />
            <path d="M252.526 100.116C248.654 101.26 124.307 139.077 64.5225 248H33.0965C43.8568 227.798 66.4332 193.812 109.575 160.125C175.846 108.226 249.559 100.364 252.526 100.116Z" fill="#EC1C24" />
        </svg>
    </Link>
);

export default Logo;