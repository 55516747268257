import { alpha, createTheme, responsiveFontSizes } from "@mui/material/styles";


const tertiary = '#231F20'
const typography = {
    fontAlata: {
        fontFamily: "Alata",
    }
}

const GlobalTheme = createTheme({
    palette: {
        mode: 'light',

        common: {
            black: '#000',
            white: '#fff',
            tertiary: tertiary,
            bodyTxt: alpha(tertiary, .6),
            grayDark: '#DFDFDF',
            grayLight: '#F3F6F5'
        },
        customColors: {
            main: '#fff',
            contrastText: '#000'
        },

        primary: {
            main: '#0ABAB5'
        },

        secondary: {
            main: '#EC1C24'
        },

        error: {
            main: '#EC1C24'
        },

        warning: {
            main: '#FFC700',
            light: '#FFF7F0'
        },

        contrastThreshold: 3,

        shape: {
            borderRadius: 2
        }
    },

    typography: {
        h1: {
            fontFamily: 'Cinzel Decorative',
            fontSize: '3rem'
        },
        h2: {
            ...typography.fontAlata,
            fontSize: '1.75rem',
            textTransform: 'uppercase'
        },
        h3: {
            ...typography.fontAlata,
            fontSize: '1.5rem'
        },
        h4: {
            ...typography.fontAlata,
            fontSize: '1.25rem'
        },
        subtitle1: {
            fontSize: '1rem'
        },

        subtitle2: {
            fontSize: '.875rem'
        }
    },

    components: {

        MuiAppBar: {
            styleOverrides: {
                root: {
                    valueLabel: ({ theme }) => ({
                        backgroundColor: `${theme.palette.common.white}`
                    })
                }
            }
        },

        MuiList: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }
        },

        MuiCardActionArea: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        '.MuiCardActionArea-focusHighlight': {
                            opacity: 0
                        }
                    }
                }

            }
        },

        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderRadius: '8px',
                }
            }
        }
    }
})

// Apply responsive font sizes
const ResponsiveGlobalTheme = responsiveFontSizes(GlobalTheme);

export { GlobalTheme, ResponsiveGlobalTheme };