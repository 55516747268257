import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Container, Stack, Typography, Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import FlightTakeoffOutlinedIcon from '@mui/icons-material/FlightTakeoffOutlined';
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import HikingOutlinedIcon from '@mui/icons-material/HikingOutlined';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import HotelsShoppingKidsTabs from './../../components/tabsFacility/tabsFacility';
import { GlobalTheme } from './../../theme';
import DynamicMantleTourDetails from './../../components/mantles/DynamicMantleTourDetails';

const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';

const iconMapping = {
    'Flights': FlightTakeoffOutlinedIcon,
    'Hotels': HotelOutlinedIcon,
    'Transfers': AirportShuttleOutlinedIcon,
    'Activities': HikingOutlinedIcon,
    // Add more mappings here if needed
};

const parseIcons = (text) => {
    const items = text.split(', ');
    const icons = items.map(item => {
        const [count, type] = item.split(' ');
        return { count: parseInt(count, 10), type };
    });
    return icons;
};

// Start of Itinerary tabs
function TabItinerary(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`itinerary-tabpanel-${index}`}
            aria-labelledby={`itinerary-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, pl: 0 }}>{children}</Box>}
        </div>
    );
}

TabItinerary.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `itinerary-tab-${index}`,
        'aria-controls': `itinerary-tabpanel-${index}`,
    };
}
// End of Itinerary

export default function TourDetails() {
    const { refer_other_id } = useParams();
    const [tour, setTourDetails] = useState(null);
    const [images, setImages] = useState([]);
    const [itineraries, setItineraries] = useState([]);
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



  
 


    // const bestTimeToVisit = [
    //     {
    //         id: 1,
    //         title: 'January',
    //         initial: 'J',
    //         best: true
    //     },
    //     {
    //         id: 2,
    //         title: 'February',
    //         initial: 'F',
    //         best: false
    //     },
    //     {
    //         id: 3,
    //         title: 'March',
    //         initial: 'M',
    //         best: true
    //     },
    //     {
    //         id: 1,
    //         title: 'April',
    //         initial: 'A',
    //         best: true
    //     },
    //     {
    //         id: 2,
    //         title: 'May',
    //         initial: 'M',
    //         best: false
    //     },
    //     {
    //         id: 3,
    //         title: 'June',
    //         initial: 'J',
    //         best: true
    //     },
    //     {
    //         id: 1,
    //         title: 'July',
    //         initial: 'J',
    //         best: true
    //     },
    //     {
    //         id: 2,
    //         title: 'August',
    //         initial: 'A',
    //         best: false
    //     },
    //     {
    //         id: 3,
    //         title: 'September',
    //         initial: 'S',
    //         best: true
    //     },
    //     {
    //         id: 1,
    //         title: 'October',
    //         initial: 'O',
    //         best: true
    //     },
    //     {
    //         id: 2,
    //         title: 'November',
    //         initial: 'N',
    //         best: false
    //     },
    //     {
    //         id: 3,
    //         title: 'December',
    //         initial: 'D',
    //         best: true
    //     },
    // ];


    useEffect(() => {
        const fetchTourDetails = async () => {
            try {
                const [dataResponse, imagesResponse, itinerariesResponse] = await Promise.all([
                    fetch(`https://aaryanholidays.in/frontend/destinations/${refer_other_id}`),
                    fetch(`https://aaryanholidays.in/frontend/gallery/${refer_other_id}`),
                    fetch(`https://aaryanholidays.in/frontend/itineraries/${refer_other_id}`)
                ]);

                const data = await dataResponse.json();
                const imagesData = await imagesResponse.json();
                const itinerariesData = await itinerariesResponse.json();
                setIsLoading(false);
                setTourDetails(data);
                console.log(tour);
                console.log("hii"+tour)
                setImages(imagesData);
                setItineraries(itinerariesData);
                
            } catch (error) {
                console.error('Error fetching tours: ', error);
            }
        }
        fetchTourDetails();
    }, [refer_other_id]);
 // Example truemonth string

 // Base JSON data
const monthsData = [
    { id: 1, title: 'January', initial: 'J' },
    { id: 2, title: 'February', initial: 'F' },
    { id: 3, title: 'March', initial: 'M' },
    { id: 4, title: 'April', initial: 'A' },
    { id: 5, title: 'May', initial: 'M' },
    { id: 6, title: 'June', initial: 'J' },
    { id: 7, title: 'July', initial: 'J' },
    { id: 8, title: 'August', initial: 'A' },
    { id: 9, title: 'September', initial: 'S' },
    { id: 10, title: 'October', initial: 'O' },
    { id: 11, title: 'November', initial: 'N' },
    { id: 12, title: 'December', initial: 'D' },
  ];
  console.log("hii"+tour)
  const truemonth = tour?.location || ''; // Use the correct field name from the API response
  const truemonthArray = truemonth.split(',').map(month => month.trim());
  
  // Create new array with `best` property
  const bestTimeToVisit = monthsData.map(month => ({
      ...month,
      best: truemonthArray.includes(month.title),
  }));
 
 console.log(bestTimeToVisit);
    const renderIcons = (text) => {
        const iconData = parseIcons(text);
        return (
            <Grid container spacing={4}>
                {iconData.map((item, index) => {
                    const IconComponent = iconMapping[item.type];
                    if (!IconComponent) return null; // Handle cases where the icon type is not found
                    return (
                        <Grid item key={index} sx={{ textAlign: 'center', pt: 0 }}>
                            <IconComponent sx={{ fontSize: GlobalTheme.typography.h3.fontSize, color: GlobalTheme.palette.common.bodyTxt }} />
                            <Typography variant="body2" sx={{ color: GlobalTheme.palette.common.bodyTxt, fontWeight: 500 }}>{item.count} {item.type}</Typography>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };
    if (isLoading) {
        return <div>Loading...</div>;
    }
    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />

                <DynamicMantleTourDetails
                    banner_type={tour?.banner_type}
                    banner_src={`${BASE_MEDIA_URL}${tour?.banner_src}`}
                    banner_alt={tour?.banner_alt}
                    banner_title={tour?.banner_title}
                    banner_description={tour?.banner_description}
                    price={tour?.price}
                    days={tour?.tour_days}
                    data={images}
                />

                <Container id="tourDetails" sx={{ backgroundColor: GlobalTheme.palette.warning.light }} maxWidth='xxl'>
                    <Container fixed>
                        <Grid display='flex' justifyContent='space-between' alignItems='center' sx={{ py: 2 }}>
                            <Grid item xs={12} sm={6}>
                                {tour && renderIcons(tour.tour_features)}
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                                <Stack direction='row' spacing={4}>
                                    <Button variant="text" color='error' startIcon={<FileDownloadOutlinedIcon />}>
                                        Brochure
                                    </Button>
                                    <Button variant="contained" size='large' endIcon={<KeyboardArrowRightOutlinedIcon />}
                                        sx={{
                                            px: 3,
                                            py: 2,
                                            color: GlobalTheme.palette.common.white,
                                            backgroundColor: GlobalTheme.palette.common.black,
                                            borderRadius: 12.5,
                                            boxShadow: 'none'
                                        }}
                                    >Book this tour</Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>

                <Container id="intro" sx={{ backgroundColor: GlobalTheme.palette.common.white }} maxWidth='xxl'>
                    <Container fixed sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', py: 6 }}>
                        <Typography variant='h2' component='h2' sx={{ mb: 3 }}>{tour?.body_heading_txt}</Typography>
                        <Typography variant='subtitle2' component='p' textAlign='center' sx={{ fontWeight: 400 }}>
                            {tour?.body_txt}
                        </Typography>
                    </Container>
                </Container>

                <Container id="funAndAdventure" sx={{ backgroundColor: GlobalTheme.palette.common.white }} maxWidth='xxl'>
                    <Container fixed>
                        <Grid sx={{ textAlign: 'center' }}>
                            <Typography variant='h3' component='h3'>All day Fun &amp; adventure</Typography>
                        </Grid>

                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="vacation itinerary">
                                    {itineraries.map((itinerary, index) => (
                                        <Tab key={itinerary.id} label={`Day ${index + 1}`} {...a11yProps(index)} />
                                    ))}
                                </Tabs>
                            </Box>
                            {itineraries.map((itinerary, index) => (
                                <TabItinerary key={itinerary.id} value={value} index={index}>
                                    <Grid display='flex'>
                                        <Grid item xs={12} sm={6} sx={{ maxHeight: '440px', overflowY: 'auto', pr: 3 }}>
                                            <Typography variant='h4' component='h4' sx={{ textTransform: 'uppercase', mb: 2 }} > {itinerary.description_heading}</Typography>
                                            {/* <Typography variant='subtitle2' component='p' sx={{ mb: 2, color: GlobalTheme.palette.common.bodyTxt }}>
                                               
                                            </Typography> */}
                                            <Typography variant='subtitle2' component='p' sx={{ mb: 2, color: GlobalTheme.palette.common.bodyTxt }}>
                                                {itinerary.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <img src={`https://aaryanholidays.in/express/uploads/${itinerary.image}`} alt='' style={{ maxWidth: 653, height: 440, borderRadius: '8px' }} />
                                        </Grid>
                                    </Grid>
                                </TabItinerary>
                            ))}
                        </Box>
                    </Container>
                </Container>

                <Container id="bestTimeToVisit" sx={{
                    backgroundColor: GlobalTheme.palette.common.grayLight,
                    py: 5
                }} maxWidth='xxl'>
                    <Container fixed sx={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={8}>
                                <Typography variant='h3' component='h3' sx={{ mb: 3 }}>BEST TIME TO VISIT</Typography>

                                <List
                                    sx={{
                                        display: 'inline-flex',
                                        mb: 3
                                    }}>
                                    {bestTimeToVisit.map(month => (
                                        <Tooltip title={month.title}>
                                            <ListItem
                                                key={month.id}
                                                sx={{
                                                    mr: 2,
                                                    fontSize: GlobalTheme.typography.subtitle2.fontSize,
                                                    backgroundColor: month.best ? GlobalTheme.palette.primary.main : GlobalTheme.palette.warning.light,
                                                    color: month.best ? GlobalTheme.palette.common.white : GlobalTheme.palette.primary.main,
                                                    border: '1px solid',
                                                    borderColor: month.best ? GlobalTheme.palette.primary.white : GlobalTheme.palette.primary.main,
                                                    borderRadius: 50,
                                                    py: 1.25
                                                }}
                                            >
                                                {month.initial}
                                            </ListItem>
                                        </Tooltip>
                                    ))}
                                </List>
                                <Typography variant='subtitle2' component='p' sx={{
                                    fontWeight: 400
                                }}>
                                    {tour?.best_time_text}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <iframe title='tour map' src= {tour?.map}  width='100%' height='300' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </Grid>

                        </Grid>

                    </Container>
                </Container>


                <Container fixed sx={{ py: 5 }}>
                    <HotelsShoppingKidsTabs/>
                </Container>

            </ThemeProvider>
        </React.Fragment>
    );
}
