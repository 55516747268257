import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import CssBaseline from '@mui/material/CssBaseline';
import { alpha, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';
import PDPSwiper from './assets/scss/swiper.scss';
import { GlobalTheme } from '../../theme';

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

const DynamicMantleTourDetails = ({ banner_type, banner_src, banner_alt, banner_title, banner_description, data, price, days }) => {
  return (
    <React.Fragment>
      <ThemeProvider theme={GlobalTheme}>
        <CssBaseline />
        <Card id="headerContainer"
          sx={{
            position: 'relative',
            zIndex: 1,
            maxHeight: '500px'
          }}>

          <Grid
            sx={{
              position: 'absolute',
              height: '100%',
              width: '40%',
              left: 0,
              bottom: 0,
              top: 0,
              backgroundColor: alpha(GlobalTheme.palette.common.black, .65),
              zIndex: 2,
            }}>
            <CardContent id="mantleText"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
                flexWrap: 'wrap',
                alignContent: 'center',
                pl: 6,
                pr: 8,
                mt: 10
              }}>
              <Typography id="mantleHeading" variant='h1' color='white'
                sx={{
                  fontSize: '2rem'
                }}>{banner_title}</Typography>
              <Typography id="mantleSubHeading" component='p' variant="subtitle1" color='white' gutterBottom
                sx={{
                  mb: 2.5
                }}>
                {truncateText(banner_description, 200)}
              </Typography>
              <Typography component='p' variant='h2' sx={{
                fontWeight: 500, mb: .75,
                textTransform: 'uppercase',
                color: GlobalTheme.palette.warning.main,
                fontSize: '1.5rem'

              }}> {days}
              </Typography>
              <Typography component='p' sx={{
                fontSize: '1rem',
                fontWeight: 500,
                color: GlobalTheme.palette.common.white
              }}> Rs. {price}
                <Typography component='span'
                  sx={{
                    fontSize: 18,
                    pl: 1
                  }}
                >
                  only
                </Typography>
              </Typography>
              <Link href="https://www.google.com/search?q=google+currency+converter&rlz=1C1ONGR_enIN990IN990&oq=google+currec&gs_lcrp=EgZjaHJvbWUqDAgBEAAYChixAxiABDIGCAAQRRg5MgwIARAAGAoYsQMYgAQyCQgCEAAYChiABDIJCAMQABgKGIAEMgkIBBAAGAoYgAQyCQgFEAAYChiABDIJCAYQABgKGIAEMgkIBxAAGAoYgAQyCQgIEAAYChiABDIJCAkQABgKGIAE0gEINjg2OWowajeoAgCwAgA&sourceid=chrome&ie=UTF-8" underline="hover" target='_blank'
                sx={{
                  mt: 1
                }}>
                Currency converter
              </Link>
            </CardContent>
          </Grid>

          <Grid
            sx={{
              background: 'linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.1) 100%)',
            }}
            width='100%'
            height='100%'
            position='absolute'>
          </Grid>

          <Swiper
            direction={'horizontal'}

            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation, EffectFade]}
            className={PDPSwiper.pdpSwiper}
          >
            {data.map((data) => (
              <SwiperSlide key={data}
                virtualIndex={data}
              >
                
                  <CardMedia
                    id={`destinationThumbsImg${data}`}
                    component="img"
                    image={data.src}
                    alt={banner_alt}
                  />
               
              </SwiperSlide>
            ))}
          </Swiper>
        </Card>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default DynamicMantleTourDetails;
