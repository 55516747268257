import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalTheme } from './../../theme';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Logo from '../logo/logo';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import Link from '@mui/material/Link';
import { alpha, Divider, List, ListItem } from '@mui/material';

import FB from './assets/images/fb.png';
import X from './assets/images/x.png';
import IN from './assets/images/in.png';
import Insta from './assets/images/insta.png';

export default function Footer() {

    const socialIcons = [
        { id: 'sc1', path: FB, link: 'https://www.facebook.com' },
        // { id: 'sc2', path: X, link: 'https://www.twitter.com' },
        // { id: 'sc3', path: IN, link: 'https://www.linkedin.com' },
        { id: 'sc4', path: Insta, link: 'https://www.instagram.com' }
    ];

    const linksSetA = [        
        { id: 'lk2', name: 'Terms & Conditions', link: '#' },
        { id: 'lk4', name: 'Privacy Policy', link: '#' },        
        { id: 'lk6', name: 'Refund & Cancellation', link: '#' },        
        { id: 'lk8', name: 'Disclaimer', link: '#' },
        { id: 'lk9', name: 'Blog', link: '#' }
    ];

    const PopularDestinations = [
        { id: 'pd1', name: 'Emirates, United Arabian', link: '#' },
        { id: 'pd2', name: 'New York City, USA', link: '#' },
        { id: 'pd3', name: 'One Bridge, Belgium', link: '#' },
        { id: 'pd4', name: 'Golden Frame, Dubai', link: '#' }
    ];

    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />

                <Container
                    component='footer'
                    maxWidth='xxl'
                    sx={{
                        backgroundColor: GlobalTheme.palette.common.tertiary,
                        backgroundAttachment: 'fixed',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundImage: 'url(https://as2.ftcdn.net/v2/jpg/02/65/26/83/1000_F_265268314_LmykO3vrtzmh3TQbBdnxj9vUczqqJXCU.jpg)',
                        p: { xs: 0 },
                        mt: 4

                    }}
                >
                    <Grid
                        sx={{
                            backgroundColor: `${alpha(GlobalTheme.palette.common.black, .65)}`,
                            height: '100%'
                        }}
                    >
                        <Container fixed >
                            <Grid container spacing={4} justifyContent="space-between">
                                <Grid item xs={12} md={4}>
                                    <Logo fillColor={GlobalTheme.palette.primary.main} />
                                    <Typography variant='h3' component='h3' sx={{ color: GlobalTheme.palette.common.white, my: 3 }}>
                                        Call Us
                                    </Typography>
                                    <Stack direction='row' spacing={2} sx={{ mb: 1 }}>
                                        <CallOutlinedIcon sx={{ color: GlobalTheme.palette.common.white }} />
                                        <Link href="call:033 4051 5000" sx={{ color: GlobalTheme.palette.common.white }}>+91 33 4051 5000</Link>
                                    </Stack>
                                    <Stack direction='row' spacing={2}>
                                        <MailOutlinedIcon sx={{ color: GlobalTheme.palette.common.white }} />
                                        <Link href="mailto:info@aaryanholidays.com" sx={{ color: GlobalTheme.palette.common.white }}>info@aaryanholidays.com</Link>
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Typography variant='h3' component='h3' sx={{ color: GlobalTheme.palette.common.white, my: 3 }}>
                                        Links
                                    </Typography>
                                    <List sx={{ columnCount: 2 }}>
                                        {linksSetA.map((links) => (
                                            <ListItem key={links.id} sx={{ pl: 0 }}>
                                                <Link href="#" id={links.id}
                                                    sx={{
                                                        color: GlobalTheme.palette.common.white,
                                                        textDecoration: 'none',
                                                        "&:hover, &:focus": {
                                                            color: GlobalTheme.palette.primary.main,
                                                            textDecoration: 'underline',
                                                            outline: '1px solid transparent'
                                                        }
                                                    }}>{links.name}</Link>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Typography variant='h3' component='h3' sx={{ color: GlobalTheme.palette.common.white, my: 3 }}>
                                        Popular Destinations
                                    </Typography>
                                    <List sx={{ columnCount: 2 }}>
                                        {PopularDestinations.map((links) => (
                                            <ListItem key={links.id} sx={{ pl: 0 }}>
                                                <Link href="#" id={links.id}
                                                    sx={{
                                                        color: GlobalTheme.palette.common.white,
                                                        textDecoration: 'none',
                                                        "&:hover, &:focus": {
                                                            color: GlobalTheme.palette.primary.main,
                                                            textDecoration: 'underline',
                                                            outline: '1px solid transparent'
                                                        }
                                                    }}>{links.name}</Link>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                        <Divider sx={{ my: 3, borderColor: GlobalTheme.palette.common.white }} />
                        <Container fixed sx={{ pb: 3 }}>
                            <Stack direction='column' justifyContent='space-between' alignItems='center'
                                sx={{
                                    flexDirection: {
                                        md: 'row'
                                    }
                                }}>
                                <Typography sx={{ color: GlobalTheme.palette.common.white }}>&copy; Aaryan Leisure & Holidays Pvt Ltd. 2017-2023. All rights reserved.</Typography>
                                <Stack direction='row' alignItems='center'>
                                    <Typography sx={{ color: GlobalTheme.palette.common.white, mr: 2 }}>Follow Us</Typography>
                                    <Stack direction='row'>
                                        {socialIcons.map((icon) => (
                                            <IconButton
                                                key={icon.id}
                                                component="a"
                                                href={icon.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    padding: 0
                                                }}
                                            >
                                                <img src={icon.path} alt={icon.id} />
                                            </IconButton>
                                        ))}
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Container>
                    </Grid>
                </Container>
            </ThemeProvider>
        </React.Fragment >
    )
}