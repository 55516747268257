import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper-bundle.min.css';
import axios from 'axios';

import { Pagination } from 'swiper/modules';

const CompetencySlider = () => {
    const [competencies, setCompetencies] = useState([]);

    useEffect(() => {
        const fetchCompetencies = async () => {
            try {
                const response = await axios.get('https://aaryanholidays.in/frontend/competencies');
                const sortedCompetencies = response.data.sort((a, b) => a.image_order - b.image_order);
                setCompetencies(sortedCompetencies);
            } catch (error) {
                console.error('Error fetching competencies:', error);
            }
        };

        fetchCompetencies();
    }, []);

    return (
        <Swiper
            breakpoints={{
                0: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                900: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                },
                1200: {
                    slidesPerView: 5,
                    spaceBetween: 50,
                },
            }}

            spaceBetween={30}
            pagination={{
                clickable: true,
            }}
            modules={[Pagination]}
            keyboard={{
                enabled: true,
            }}
            style={{ paddingBottom: '40px' }}
        >
            {competencies.map((competency) => (
                <SwiperSlide key={competency.id}>
                    <img src={`https://aaryanholidays.in/express/uploads/${competency.image}`} alt={competency.alt} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default CompetencySlider;
