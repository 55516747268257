

import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import DynamicMantle from './mantles/DynamicMantle';
import { GlobalTheme } from './../theme';
import { Container, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TablePagination from '@mui/material/TablePagination';
import { alpha } from '@mui/system';
import Box from '@mui/material/Box';

import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';

import HomeMantle from './../components/mantles/mantle';

const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';

const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};

export default function SearchResultPage() {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const keywords = query.get('keywords');
    const videoRefs = useRef([]);
    const navigate = useNavigate();
    const handleMouseEnter = (index) => {
        const videoElement = videoRefs.current[index];
        if (videoElement && videoElement.paused) {
            videoElement.play().catch(error => console.error('Error playing video:', error));
        }
    };

    const handleMouseLeave = (index) => {
        const videoElement = videoRefs.current[index];
        if (videoElement && !videoElement.paused) {
            videoElement.pause();
            videoElement.currentTime = 0;
        }
    };
    const handleCardClick = (id) => {
        navigate(`/tour-details/4/${id}`)
    }
    useEffect(() => {
        if (keywords) {
            const fetchResults = async () => {
                try {
                    const response = await fetch(`https://aaryanholidays.in/frontend/search-destinations?keywords=${encodeURIComponent(keywords)}`);
                    console.log(`https://aaryanholidays.in/frontend/search-destinations?keywords=${encodeURIComponent(keywords)}`);
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const data = await response.json();
                    setResults(data);
                } catch (error) {
                    console.error('Error fetching search results:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchResults();
        }
    }, [keywords]);

    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                {/* <HomeMantle home={1} /> */}
                {/* <DynamicMantle
                    banner_type={tourTypeData.banner_type}
                    banner_src={`${BASE_MEDIA_URL}${tourTypeData.banner_src}`}
                    banner_alt={tourTypeData.banner_alt}
                    banner_title={tourTypeData.banner_title}
                    banner_description={tourTypeData.banner_description}
                /> */}
                <Box>
                    <CardMedia
                        component="img"
                        height="500"
                        image="https://as1.ftcdn.net/v2/jpg/02/54/76/30/1000_F_254763087_qAqKHN1286EPaCTqfG8lWjIJ6CT2SAM8.jpg"
                        alt=""
                    />
                </Box>
                <Container fixed sx={{ py: 5 }}>
                    <Grid textAlign='center'>
                        <Typography id="destinationHeading" component='h2' variant='h2' sx={{ mb: 2 }}>
                            Search Result  For "{keywords}"
                        </Typography>

                    </Grid>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : results.length === 0 ? (
                        <Typography>No results found</Typography>
                    ) : (
                        <Grid container spacing={4} id='tourListing' sx={{ mt: 2 }}>
                            {results.map((tour, index) => (
                                <Grid
                                    item
                                    id={`tourListing${tour.id}`}
                                    key={tour.id}
                                    xs={6}
                                    md={3}
                                    sx={{ display: 'inline-flex' }}
                                >
                                    <Card
                                        sx={{
                                            position: 'relative',
                                            borderRadius: 2,
                                            height: 348,
                                            overflow: 'hidden'
                                        }}>
                                        <CardActionArea
                                            id={'destinationListing' + tour.id}
                                            onClick={() => handleCardClick(tour.id)}
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={() => handleMouseLeave(index)}
                                            sx={{ height: 'inherit' }}
                                        >

                                            {tour.type === 'video' ? (
                                                <CardMedia
                                                    id={`destinationThumbsVid${index}`}
                                                    component="video"
                                                    src={`${BASE_MEDIA_URL}${tour.src}`}
                                                    loop
                                                    muted
                                                    playsInline
                                                    ref={(el) => (videoRefs.current[index] = el)}
                                                >
                                                    <source src={`${BASE_MEDIA_URL}${tour.src}`} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </CardMedia>
                                            ) : (
                                                <CardMedia
                                                    id={`destinationThumbsImg${index}`}
                                                    component="img"
                                                    src={`${BASE_MEDIA_URL}${tour.src}`}
                                                    alt={tour.alt}
                                                />
                                            )}

                                            <CardContent
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    left: 0,
                                                    textAlign: 'left',
                                                    height: '100%',
                                                    width: '100%',
                                                    pt: 35,
                                                    transition: 'all .4s ease-in',
                                                    "&:hover": {
                                                        pt: 23,
                                                        backgroundColor: alpha(GlobalTheme.palette.common.tertiary, 0.5)
                                                    }
                                                }}>
                                                <Typography variant='h3' component='h3' sx={{ color: GlobalTheme.palette.common.white }} gutterBottom>
                                                    <span dangerouslySetInnerHTML={{ __html: tour.thumbnail_heading }} />
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: GlobalTheme.palette.common.white }}>
                                                    {truncateText(tour.thumbnail_desc, 80)}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}

                        </Grid>
                    )}


                </Container>
            </ThemeProvider>
        </React.Fragment>
    )
}



