import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import CssBaseline from '@mui/material/CssBaseline';
import { alpha, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { ThemeProvider } from '@mui/material/styles';
import { Pagination } from 'swiper/modules';

import { GlobalTheme } from '../../theme';

const DynamicMantle = ({ banner_type, banner_src, banner_alt, banner_title, banner_description }) => {
  return (
    <React.Fragment>
      <ThemeProvider theme={GlobalTheme}>
        <CssBaseline />
        <Card id="headerContainer"
          sx={{
            position: 'relative',
            zIndex: 1,
            maxHeight: '500px'
          }}>

          <Grid
            sx={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              textAlign: 'center',
              left: 0,
              bottom: 0,
              top: 0,
              backgroundColor: alpha(GlobalTheme.palette.common.black, .65),
              zIndex: 2,
            }}>
            <CardContent id="mantleText"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
                flexWrap: 'wrap',
                alignContent: 'center',
                pl: 6,
                pr: 8,
                mt: 10
              }}>
              <Typography id="mantleHeading" gutterBottom variant='h1' color='white' >{banner_title}</Typography>
              <Typography id="mantleSubHeading" component='p' variant="subtitle1" color='white' gutterBottom
                sx={{
                  mb: 2.5,
                  px: 35
                }}>
                {banner_description}

              </Typography>
            </CardContent>
          </Grid>

          <Grid
            sx={{
              background: 'linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.1) 100%)',
            }}
            width='100%'
            height='100%'
            position='absolute'>
          </Grid>

          <Swiper
            direction={'vertical'}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              {banner_type === 'video' ? (
                <CardMedia
                  id="mantleMedia"
                  component="video"
                  autoPlay
                  loop
                  muted
                  controls
                  playsInline>
                  <source src={banner_src} type="video/mp4" />
                  <Typography variant='subtitle2' component='p'> Your browser does not support the video tag.</Typography>
                </CardMedia>
              ) : (
                <CardMedia
                  id="mantleMedia"
                  component="img"
                  image={banner_src}
                  alt={banner_alt}
                />
              )
              }
            </SwiperSlide>
          </Swiper>
        </Card>
      </ThemeProvider>
    </React.Fragment >
  );
};

export default DynamicMantle;
