import React, { useState } from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { GlobalTheme } from '../../theme';

import Button from '@mui/material/Button';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import TravelRequestDialog from './../dialog/travel_request';
import PropTypes from 'prop-types';

const TravelRequest = ({size = 'large', window, ...props}) => {
    const [openTravelRequest, setOpenTravelRequest] = useState(false);

    const handleOpenTravelRequest = () => {
        setOpenTravelRequest(true);
    };

    const handleCloseTravelRequest = () => {
        setOpenTravelRequest(false);
    };

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 800,
        target: window ? window() : undefined,
    });

    return (
        <>
            <Button
                variant="contained"
                disableFocusRipple
                disableElevation
                endIcon={<EastOutlinedIcon />}
                size={size}
                {...props}
                sx={{
                    backgroundColor: trigger ? GlobalTheme.palette.common.black : GlobalTheme.palette.common.tertiary,
                    color: GlobalTheme.palette.common.white,
                    borderRadius: 0,
                    textTransform: 'uppercase',
                    fontSize: GlobalTheme.typography.subtitle1.fontSize,
                    transition: 'margin-left 0.3s ease-in-out',
                    py: 2,
                    px: 3,
                    "& .MuiButton-endIcon": {
                        display: 'none',
                        transition: 'display 0.3s ease-in-out',
                    },
                    "&:hover .MuiButton-endIcon, &:focus .MuiButton-endIcon": {
                        display: 'inline-flex',
                    }
                }}
                onClick={handleOpenTravelRequest}
            >
                Travel Request
            </Button>

            <TravelRequestDialog open={openTravelRequest} onClose={handleCloseTravelRequest} />
        </>
    );
}

TravelRequest.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    children: PropTypes.node.isRequired,
};

export default TravelRequest;