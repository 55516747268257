import React, { useEffect, useState, useCallback } from 'react';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Scrollbar, Mousewheel } from 'swiper/modules';
import Button from '@mui/material/Button';

import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import VideoMantle from './../../components/video/video';
import { GlobalTheme } from '../../theme';

const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};



export default function ManagePartners() {
    const [partners, setPartners] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [loading, setLoading] = useState(true);
    const [direction, setDirection] = useState('horizontal');

    useEffect(() => {
        const handleResize = () => {
            if (window.matchMedia('(min-width: 960px)').matches) {
                setDirection('vertical');
            } else {
                setDirection('horizontal');
            }
        };

        handleResize(); // Call it initially
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://aaryanholidays.in/frontend/partners');
                const data = await response.json();

                if (Array.isArray(data)) {
                    const formattedData = data.map(partner => ({
                        ...partner,
                        thumbnailUrl: `https://img.youtube.com/vi/${partner.video_link}/hqdefault.jpg`
                    }));
                    setPartners(formattedData);
                    if (formattedData.length > 0) {
                        setSelectedPartner(formattedData[0]); // Set the first partner as default
                    }
                } else {
                    console.error('Unexpected data format:', data);
                }
            } catch (error) {
                console.error('Error fetching partner data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleVideoSelect = useCallback((partner) => {
        setSelectedPartner(partner);
    }, []);

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    if (!Array.isArray(partners) || partners.length === 0) {
        return <Typography>No partners data available.</Typography>;
    }

    return (
        <ThemeProvider theme={GlobalTheme}>
            <CssBaseline />


            <Grid container spacing={2}
            >
                <Grid item xs={12} md={10}>
                    {selectedPartner && <VideoMantle embedId={selectedPartner.video_link} showControl={1} mute={0} />}
                    {selectedPartner && (
                        <>
                            <Typography variant='h5' component='h3' sx={{ textAlign: 'left', mt: 2, mb: 1 }}>
                                {selectedPartner.name}
                            </Typography>
                            <Typography variant='body1' component='p' sx={{ textAlign: 'left' }}>
                                {truncateText(selectedPartner.brief, 300)}
                            </Typography>
                        </>
                    )}
                </Grid>
                <Grid item xs={12} md={2}>
                    <Swiper
                         direction={direction}
                        slidesPerView={'auto'}
                        freeMode={true}
                        scrollbar={true}
                        mousewheel={true}
                        modules={[FreeMode, Scrollbar, Mousewheel]}
                        style={{
                            maxHeight: '510px'
                        }}
                    >
                        <SwiperSlide>

                            {partners.map((partner, index) => (
                                <MemoizedPartnerListItem key={index} partner={partner} onSelect={handleVideoSelect} />
                            ))}

                        </SwiperSlide>
                    </Swiper>
                </Grid>
            </Grid>
        </ThemeProvider >
    );
}

const PartnerListItem = ({ partner, onSelect }) => {
    return (
        <Button variant="text" onClick={() => onSelect(partner)} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', p: 0, textAlign: 'left', mb: 1, color: GlobalTheme.palette.common.black }}>
            <ListItemAvatar sx={{ width: '100%', display: 'block', mb: .5 }}>
                <Avatar src={partner.thumbnailUrl} sx={{ width: 160, height: 90, borderRadius: 2 }} />
            </ListItemAvatar>
            <Typography component='p' variant='subtitle2' sx={{ fontWeight: 400, textTransform: 'none' }}>{partner.name}</Typography>
        </Button>
    );
};

const MemoizedPartnerListItem = React.memo(PartnerListItem);
