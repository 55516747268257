import React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { CardActionArea, Typography, alpha } from '@mui/material';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import HouseboatOutlinedIcon from '@mui/icons-material/HouseboatOutlined';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import DriveEtaOutlinedIcon from '@mui/icons-material/DriveEtaOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import AboutImg from './assets/images/about.png';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalTheme } from '../../theme';

import partnersImg from './assets/images/together.png';
import AaryanTimeline from './timeline';

import MantleImg from './assets/images/mantle.jpeg';

const offer = [
    {
        id: 1,
        icon: <HouseboatOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
        title: 'Indian<br/>Holiday',
        brief: 'Immigration advisory visa a foundation was establishe with a  ideaImmigration advisory visa a foundation'
    },
    {
        id: 2,
        icon: <LuggageOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
        title: 'International<br/>Holiday',
        brief: 'Immigration advisory visa a foundation was establishe with a  ideaImmigration advisory visa a foundation'
    }
]
const offerCont = [
    {
        id: 3,
        icon: <AirplaneTicketOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
        title: 'Ait Ticket<br/>& Rail Ticket',
        brief: 'Immigration advisory visa a foundation was establishe with a  ideaImmigration advisory visa a foundation'
    },
    {
        id: 4,
        icon: <HotelOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
        title: 'Hotel<br/>Booking',
        brief: 'Immigration advisory visa a foundation was establishe with a  ideaImmigration advisory visa a foundation'
    },
    {
        id: 5,
        icon: <DriveEtaOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
        title: 'Car<br/>Rental',
        brief: 'Immigration advisory visa a foundation was establishe with a  ideaImmigration advisory visa a foundation'
    },
    {
        id: 6,
        icon: <ArticleOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
        title: 'Passport<br/>Visa',
        brief: 'Immigration advisory visa a foundation was establishe with a  ideaImmigration advisory visa a foundation'
    }

]

const achivements = [
    {
        id: 1,
        icon: <CardMembershipOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
        title: 'Accredited<br/>Membership',
        brief: 'Immigration advisory visa a foundation was establishe with a ideaImmigration advisory'
    },
    {
        id: 2,
        icon: <EmojiEventsOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
        title: 'Our<br/>Achievements',
        brief: 'Immigration advisory visa a foundation was establishe with a ideaImmigration advisory'
    },
    {
        id: 3,
        icon: <RedeemOutlinedIcon sx={{ fontSize: '6rem', fill: 'none', stroke: 'currentcolor', strokeWidth: '.03rem' }} />,
        title: 'Our<br/>Certifications',
        brief: 'Immigration advisory visa a foundation was establishe with a ideaImmigration advisory'
    }
]

export default function About({ home }) {
    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                <Card id="headerContainer"
                    sx={{
                        position: 'relative',
                        zIndex: 1,
                        maxHeight: '500px'
                    }}>

                    <Grid
                        sx={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            textAlign: 'center',
                            left: 0,
                            bottom: 0,
                            top: 0,
                            backgroundColor: alpha(GlobalTheme.palette.common.black, .65),
                            zIndex: 2,
                        }}>
                        <CardContent id="mantleText"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%',
                                flexDirection: 'column',
                                flexWrap: 'wrap',
                                alignContent: 'center',
                                pl: 6,
                                pr: 8,
                                mt: 10
                            }}>
                            <Typography id="mantleHeading" gutterBottom variant='h1' color='white' >Aaryan, Who are we</Typography>
                            <Typography id="mantleSubHeading" component='p' variant="subtitle1" color='white' gutterBottom
                                sx={{
                                    mb: 2.5,
                                    px: 35
                                }}>
                                Our strength lies in our Knowledge, Experience, Trust, and Expertise, which have been the foundation of our success. We are grateful to our valued customers for their continued support throughout this incredible journey.
                            </Typography>
                        </CardContent>
                    </Grid>

                    <Grid
                        sx={{
                            background: 'linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.1) 100%)',
                        }}
                        width='100%'
                        height='100%'
                        position='absolute'>
                    </Grid>

                    <CardMedia
                        id="mantleMedia"
                        component="img"
                        image={MantleImg}
                        alt=''
                    />
                </Card>

                <Container fixed component='section' aria-labelledby='companyHeading'>
                    <Grid display='flex' alignItems='center' sx={{ my: 8 }}>
                        <Grid item xs={12} sm={6} sx={{ pr: 3 }}>
                            <Typography id='companyHeading' component='h2' variant='h2' sx={{ mb: 3 }}>About AaryanE</Typography>
                            <Typography component='p' variant='subtitle1' sx={{ color: GlobalTheme.palette.common.bodyTxt }}>
                                Established in June 2004, Aaryan Leisure & Holidays has grown into one of Eastern India’s leading travel companies. With 20 years of experience, we’ve consistently provided exceptional services to travelers, crafting journeys that are both memorable and seamless.
                                <br /><br />
                              
                                At Aaryan Leisure & Holidays, we offer endless ways to explore both India and the World. Our thoughtfully curated hotel selections ensure your comfort, transforming your travel experience into one you’ll cherish for years to come.
                                <br /><br />
                                We understand that every traveler is unique, which is why we go the extra mile to personalize your dream holidays. For years, we’ve delighted thousands of travelers with tailor-made itineraries, blending extraordinary destinations, breathtaking sightseeing, and exquisite accommodations for the ultimate luxury experience.
                                <br /><br />
                                You can rely on us to design custom holiday packages that are hassle-free and perfectly suited to your preferences, ensuring a pleasant and unforgettable journey.
                                <br /><br />

                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <img src={AboutImg} alt='' style={{ maxWidth: 400 }} />
                        </Grid>
                    </Grid>
                </Container>


                <Container maxWidth="xxl" component='section' aria-labelledby='missionVisionHeading'
                    sx={{
                        backgroundImage: 'url(https://as2.ftcdn.net/v2/jpg/03/83/02/87/1000_F_383028768_5hBYmxHlKFnQ1gsp3BQtMD2Sz6ivzVYv.jpg)',
                        backgroundSize: 'cover',
                        backgroundAttachment: 'fixed',
                        backgroundPosition: 'center',
                        py: 10

                    }}>
                    <Container fixed>
                        <Grid sx={{ textAlign: 'center', px: '15rem' }}>
                            <Typography id='missionVisionHeading' variant='h3' component='h3' sx={{ mb: 3 }}>Our mission & visions</Typography>
                            <Typography variant='h4' component='h4' sx={{ mb: 3 }}> Mission </Typography>
                            <Typography variant='subtitle2' component='p'>
                                Our diverse range of offerings includes Leisure Tours, Cultural Tours, Honeymoon Packages, Weekend Getaways, Special-Interest Tours, Cruises, Corporate Conferences, Educational Excursions, etc. Whether you’re looking to explore India or venture beyond, we specialize in making your travel dreams a reality.
                                <br /><br />
                            </Typography>

                            <Typography variant='subtitle2' component='p' x>
                                At Aaryan, we aim to deliver both Leisure & Travel, providing experiences that leave lasting impressions and turn your dreams into reality.
                                <br /><br />
                            </Typography>
                        </Grid>
                    </Container>
                </Container>

                <Container maxWidth="xxl" component='section' aria-labelledby='travelSpecialistHeading whoWeAreHeading'
                    sx={{
                        backgroundColor: GlobalTheme.palette.common.grayLight
                    }}
                >
                    <Container fixed sx={{ py: 10 }}>
                        <Grid display='flex' alignItems='center'>
                            <Grid item xs={12} sm={6}>
                                <img src={partnersImg} alt='' />
                            </Grid>


                            <Grid item xs={12} sm={6} sx={{
                                px: 5
                            }}>
                                <Typography id='travelSpecialistHeading' component='h4' variant='h3' color='primary' gutterBottom sx={{ textTransform: 'uppercase' }}>
                                    Travel <br />Specialist
                                </Typography>
                                <Typography id='whoWeAreHeading' component='h2' variant='h2' sx={{ mb: 5 }}>
                                    WHO WE ARE
                                </Typography>
                                <Typography variant='subtitle2' component='p' sx={{ color: GlobalTheme.palette.common.bodyTxt }}>
                                    <strong style={{ color: GlobalTheme.palette.common.black }}>Mr. Sanjeev Mehra's</strong> foresight, determination and innovation are the key success for his business. Prior to stepping into his own venture he was also associated with Sita World Travel (I) Pvt. Ltd where he rose from Visa assistant to the level of Branch Manager. He served the organization for almost 15 years and gained adequate knowledge in the field of Tourism. His experience from the travel industry has given the advantage of proving himself as a successful entrepreneur.
                                    <br /><br />
                                    <strong style={{ color: GlobalTheme.palette.common.black }}>Mrs. Vanessa Mehra</strong> served the travel industry over a decade before joining Aaryan. She was associated with renowned travel agency “Sita World Travel”. She started her career as counter staff and due to her foresightedness and ability she rose to the level of Senior Manager. Her past experience in the travel industry has given the advantage of proving herself as an entrepreneur and supported Mr Mehra to start his own travel agency in the year 2001.
                                </Typography>
                            </Grid>

                        </Grid>
                    </Container>
                </Container>

                <Container fixed sx={{ py: 10 }} component='section' aria-labelledby='ourJourneyHeading'>
                    <Grid>
                        <Typography id='ourJourneyHeading' component='h2' variant='h2' sx={{ mb: 2, fontWeight: 600, textAlign: 'center' }}>Our Journey</Typography>
                        <AaryanTimeline />
                    </Grid>

                </Container>

                <Container maxWidth="xxl" component='section' aria-labelledby='ourServiceHeading whatWeOfferHeading'
                    sx={{
                        backgroundColor: GlobalTheme.palette.primary.main
                    }}
                >
                    <Container fixed sx={{ py: 10 }}>
                        <Grid item xs display='flex' spacing={3} justifyContent='space-between' flexWrap='wrap'>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} display='flex' flexDirection='column' justifyContent='flex-end' sx={{ mb: 6 }}>
                                    <Typography id='ourServiceHeading' component='h4' variant='h4' sx={{ textTransform: 'uppercase', fontWeight: 600, mb: 1 }}>
                                        Our <br />Services
                                    </Typography>
                                    <Typography id='whatWeOfferHeading' component='h3' variant='h2' color='white' sx={{ textTransform: 'none' }}>
                                        What We Offer For You <br />In Great Packages
                                    </Typography>
                                </Grid>

                                {offer.map((item, index) => (
                                    <Grid key={item} id={`holiday${item.id}`} item xs={12} sm={3}
                                        sx={{
                                            mb: 3,
                                            position: 'relative',
                                            overflow: 'hidden',
                                            borderRadius: 2,
                                            width: 258,
                                            height: 348,
                                        }}>
                                        <Card elevation={0}
                                            sx={{
                                                borderRadius: 2,
                                                textAlign: 'center',
                                                height: '100%',
                                                backgroundColor: 'transparent',
                                                border: '1px solid',
                                                borderColor: GlobalTheme.palette.common.white,
                                                color: GlobalTheme.palette.common.white,
                                                transition: 'all .2s ease-in',
                                                "&:hover": {
                                                    backgroundColor: GlobalTheme.palette.common.white,
                                                    color: GlobalTheme.palette.common.black
                                                },

                                                "&:hover .briefText": {
                                                    pt: 23
                                                }



                                            }}>
                                            <CardContent sx={{
                                                height: '100%',
                                                position: 'absolute',
                                                pt: 12.5,
                                                transition: 'all .4s ease-in',

                                                "&:hover": {
                                                    pt: 6.25
                                                },

                                                "&:hover .briefText": {
                                                    pt: 1.25
                                                }

                                            }}>
                                                {item.icon}
                                                <Typography gutterBottom variant="h4" component="h4">
                                                    <span dangerouslySetInnerHTML={{ __html: item.title }} />
                                                </Typography>
                                                <Typography variant="body2" component='p' sx={{ pt: 12.5 }} className='briefText'>
                                                    {item.brief}
                                                </Typography>
                                            </CardContent>

                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>

                            <Grid container spacing={3}>

                                {offerCont.map((item, index) => (
                                    <Grid key={item} id={`holiday${item.id}`} item xs={12} sm={3}
                                        sx={{
                                            mb: 3,
                                            position: 'relative',
                                            overflow: 'hidden',
                                            borderRadius: 2,
                                            width: 258,
                                            height: 348,
                                        }}>
                                        <Card elevation={0}
                                            sx={{
                                                borderRadius: 2,
                                                textAlign: 'center',
                                                height: '100%',
                                                backgroundColor: 'transparent',
                                                border: '1px solid',
                                                borderColor: GlobalTheme.palette.common.white,
                                                color: GlobalTheme.palette.common.white,
                                                transition: 'all .2s ease-in',
                                                "&:hover": {
                                                    backgroundColor: GlobalTheme.palette.common.white,
                                                    color: GlobalTheme.palette.common.black
                                                },

                                                "&:hover .briefText": {
                                                    pt: 23
                                                }



                                            }}>
                                            <CardContent sx={{
                                                height: '100%',
                                                position: 'absolute',
                                                pt: 12.5,
                                                transition: 'all .4s ease-in',

                                                "&:hover": {
                                                    pt: 6.25
                                                },

                                                "&:hover .briefText": {
                                                    pt: 1.25
                                                }

                                            }}>
                                                {item.icon}
                                                <Typography gutterBottom variant="h4" component="h4">
                                                    <span dangerouslySetInnerHTML={{ __html: item.title }} />
                                                </Typography>
                                                <Typography variant="body2" component='p' sx={{ pt: 12.5 }} className='briefText'>
                                                    {item.brief}
                                                </Typography>
                                            </CardContent>

                                        </Card>
                                    </Grid>
                                ))}

                            </Grid>
                        </Grid>
                    </Container>
                </Container>

                <Container maxWidth="xxl" component='section' aria-labelledby='ourPrestigeHeading whatWeOfferHeading'
                    sx={{
                        backgroundColor: GlobalTheme.palette.common.grayLight
                    }}
                >
                    <Container fixed sx={{ py: 10 }}>
                        <Grid sx={{ textAlign: 'center', mb: 5 }}>
                            <Typography id='ourPrestigeHeading' component='h4' color='primary' variant='h4' sx={{ textTransform: 'uppercase', fontWeight: 600, mb: 1 }}>
                                Our Prestige
                            </Typography>
                            <Typography id='whatWeOfferHeading' component='h3' variant='h2' sx={{ textTransform: 'none' }}>
                                RECOGNITION &  ACHIEVEMENTS
                            </Typography>
                        </Grid>

                        <Stack direction='row' spacing={5}>

                            {achivements.map((item, index) => (

                                <Card elevation={0} key={item} id={`holiday${item.id}`}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: 2,
                                        width: '100%',
                                        height: 348,
                                        alignItems: 'center',
                                        textAlign: 'center',

                                        backgroundColor: GlobalTheme.palette.common.white,

                                        "&:hover": {
                                            backgroundColor: GlobalTheme.palette.primary.main,
                                            color: GlobalTheme.palette.common.white
                                        }
                                    }}>
                                    <CardContent
                                        sx={{
                                            transition: 'all .4s ease-in',

                                            "&:hover": {
                                                pt: .25
                                            }
                                        }}
                                    >
                                        <CardActionArea>
                                            {item.icon}
                                            <Typography gutterBottom variant="h4" component="h4" fontWeight='500'>
                                                <span dangerouslySetInnerHTML={{ __html: item.title }} />
                                            </Typography>
                                            {/* <Typography variant="body2" component='p' sx={{ pt: 12 }} className='briefText'>
                                                    {item.brief}
                                                </Typography> */}
                                        </CardActionArea>
                                    </CardContent>
                                </Card>

                            ))}
                        </Stack>
                    </Container>
                </Container>



            </ThemeProvider>
        </React.Fragment >
    )
}