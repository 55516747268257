import React, { useEffect, useState, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import { alpha } from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { ThemeProvider } from '@mui/material/styles';
import { GlobalTheme } from '../../theme';

import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import './assets/scss/style.scss';

const timelineEvents = [
    { time: "2014", icon: <DateRangeOutlinedIcon />, main: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
    { time: "2016", icon: <DateRangeOutlinedIcon />, main: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
    { time: "2018", icon: <DateRangeOutlinedIcon />, main: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
    { time: "2020", icon: <DateRangeOutlinedIcon />, main: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
];

export default function CustomizedTimeline() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const timelineRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset + window.innerHeight;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isInViewport = (element) => {
        if (!element) return false;
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    return (
        <React.Fragment>
            <ThemeProvider theme={GlobalTheme}>
                <CssBaseline />
                <Timeline position="alternate" ref={timelineRef}>
                    {timelineEvents.map((event, index) => (
                        <TimelineItem key={index} ref={(el) => {
                            if (el && scrollPosition > el.getBoundingClientRect().top + window.scrollY) {
                                el.classList.add('in-viewport');
                            }
                        }}>
                            <TimelineSeparator>
                                <TimelineConnector
                                    className={`timeline-connector ${scrollPosition > (index + 1) * 100 ? 'timeline-connector-filled' : ''}`}
                                />
                                <TimelineDot className={`timeline-dot ${scrollPosition > (index + 1) * 100 ? 'timeline-dot-filled' : ''}`}>
                                    {event.icon}
                                </TimelineDot>
                                <TimelineConnector
                                    className={`timeline-connector ${scrollPosition > (index + 1) * 100 ? 'timeline-connector-filled' : ''}`}
                                />
                            </TimelineSeparator>

                            <TimelineContent sx={{ py: '12px', px: 2, borderRadius: 3 }} >
                                <Paper elevation={24} sx={{
                                    p: 4,
                                    boxShadow: `0 0 20px 4px ${alpha(GlobalTheme.palette.common.black, 0.1)}`
                                }}>
                                    <Typography variant="h2" component="h2" gutterBottom sx={{ fontWeight: 600, }}>
                                        {event.time}
                                    </Typography>
                                    <Typography color='common.tertiary'>{event.main}</Typography>

                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </ThemeProvider>
        </React.Fragment>
    );
}
