import React, { useEffect, useState } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Virtual, Navigation, Pagination, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { GlobalTheme } from '../../theme';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Stack from '@mui/material/Stack';
import HotelStyle from './assets/scss/hotels.module.scss';


const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};

export default function KidsArena() {
    const { refer_other_id } = useParams();
    const [hotelsData, setHotelsData] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [swiperRef, setSwiperRef] = useState(null);

    const handleCardClick = (id) => {
        navigate(`/hotels/${id}`);
    };

    useEffect(() => {
        const fetchHotelsData = async () => {
            try {
                const response = await fetch(`https://aaryanholidays.in/frontend/special-feature-package-wise/${refer_other_id}`);
                const data = await response.json();

                if (Array.isArray(data)) {
                    // Filter only "Hotel" type entries
                    const hotelData = data.filter(item => item.type === 'Kids Arena').map(item => ({
                        ...item,
                        image: `https://aaryanholidays.in/express/uploads/${item.thumbnail}`
                    }));

                    setHotelsData(hotelData);
                } else {
                    console.error('Unexpected data format:', data);
                }
            } catch (error) {
                console.error('Error fetching hotels data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchHotelsData();
    }, []);

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    if (!Array.isArray(hotelsData) || hotelsData.length === 0) {
        return <Typography>No Kids Arena data available.</Typography>;
    }

    return (
        <ThemeProvider theme={GlobalTheme}>
            <CssBaseline />

            <Swiper
                className={HotelStyle.hotels}
                modules={[Virtual, Navigation, Pagination, Keyboard]}
                onSwiper={setSwiperRef}
                // breakpoints={{
                //     0: { slidesPerView: 1 },
                //     600: { slidesPerView: 2 },
                //     900: { slidesPerView: 3 },
                //     1200: { slidesPerView: 4 }
                // }}
                slidesPerView={4}
                spaceBetween={30}
                navigation={true}
                virtual
                freeMode={true}
                grabCursor={true}
                keyboard={{ enabled: true }}
            >
                {hotelsData.map((hotel) => (
                    <SwiperSlide key={hotel.id}>
                        <Card
                            id={`hotelsCard-${hotel.id}`}
                            sx={{
                                boxShadow: '0 0 1px 1px rgba(0,0,0,0.1)',
                                p: 0,
                                mb: 3,
                                "&:hover": {
                                    boxShadow: '0 0 30px 5px rgba(0,0,0,0.2)',
                                }
                            }}
                        >
                            <CardContent
                                id={`hotelsBtn-${hotel.id}`}
                                component='figure'
                                sx={{
                                    p: 0,
                                    m: 0,
                                    borderRadius: 2,
                                    "&:hover": {
                                        backgroundColor: 'transparent',
                                        boxShadow: 'none'
                                    }
                                }}
                                onClick={() => handleCardClick(hotel.id)}
                            >
                                <CardMedia
                                    component="img"
                                    height="153"
                                    width="220"
                                    image={hotel.image}
                                    alt={hotel.title}
                                    sx={{
                                        borderTopLeftRadius: 1,
                                        borderTopRightRadius: 1,
                                        borderBottomRightRadius: 0,
                                        borderBottomLeftRadius: 0,
                                    }}
                                />
                                <CardContent component='figcaption' sx={{ textAlign: 'left', px: 2 }}>
                                    <Typography gutterBottom variant="h4" component="h3" sx={{ textTransform: 'uppercase', mb: 2 }}>
                                        {hotel.title}
                                    </Typography>
                                    <Stack component='address' spacing={1} direction='row' sx={{ mb: 2, fontStyle: 'normal' }}>
                                        <LocationOnOutlinedIcon />
                                        <Typography variant='subtitle2' component='p'>
                                            {truncateText(hotel.description, 50)}
                                        </Typography>
                                    </Stack>
                                    <Link href={hotel.website_url} underline="hover" rel="noopener" target="_blank">
                                        <Stack component='span' direction='row' spacing={1} alignItems='center'>
                                            <LanguageOutlinedIcon sx={{color: GlobalTheme.palette.common.black}}/>
                                            <Box>
                                                View Website
                                            </Box>
                                        </Stack>
                                    </Link>
                                </CardContent>
                            </CardContent>
                        </Card>
                    </SwiperSlide>
                ))}
            </Swiper>

        </ThemeProvider>
    );
}
